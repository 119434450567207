import { Text } from '@chakra-ui/react';

function StrikeText({ children }: React.PropsWithChildren<{}>) {
  return (
    <Text
      as="span"
      display="flex"
      alignItems="center"
      textAlign="center"
      w="full"
      _before={{
        content: '""',
        flex: 1,
        borderBottom: '1px',
        borderColor: 'inherit',
        marginRight: 4,
      }}
      _after={{
        content: '""',
        flex: 1,
        borderBottom: '1px',
        borderColor: 'inherit',
        marginLeft: 4,
      }}
    >
      {children}
    </Text>
  );
}

export default StrikeText;
