import { Flex, Text } from '@chakra-ui/react';
import Trans from 'next-translate/Trans';
import React, { forwardRef } from 'react';

import useItemTranslation from 'hooks/useItemTranslation';
import { CategoriesSearch_categories_edges_node } from 'lib/graphql/types/CategoriesSearch';

export interface SearchCategoryItem {
  category: CategoriesSearch_categories_edges_node;
  highlighted?: boolean;
}

const SearchCategoryItem = forwardRef<HTMLDivElement, SearchCategoryItem>(
  ({ category, highlighted, ...rest }, ref) => {
    const { localeField } = useItemTranslation();

    return (
      <Flex
        ref={ref}
        p={4}
        cursor="pointer"
        bg={highlighted ? 'darkGray.100' : 'transparent'}
        _hover={{
          bg: 'darkGray.100',
        }}
        _focus={{
          bg: 'darkGray.100',
        }}
        {...rest}
      >
        <Flex align="center">
          <Trans
            i18nKey="common:searchCategory"
            components={[<Text as="strong" ml={1} />]}
            values={{ count: category.totalProducts, category: localeField(category, 'name') }}
          />
        </Flex>
      </Flex>
    );
  },
);

SearchCategoryItem.displayName = 'SearchCategoryItem';

export default SearchCategoryItem;
