import { Button, ButtonProps, Flex } from '@chakra-ui/react';
import { MouseEventHandler, PropsWithChildren } from 'react';

interface MenuLinkProps extends ButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  noBorder?: boolean;
}

function MenuLink({
  children,
  onClick,
  noBorder = false,
  ...rest
}: PropsWithChildren<MenuLinkProps>) {
  return (
    <Flex borderBottom={`${noBorder ? '' : '1px'}`} borderBottomColor="darkGray.100" w="full">
      <Button
        variant="link"
        p={4}
        display="inline-block"
        fontWeight="semibold"
        flex={1}
        textAlign="left"
        color="black"
        _hover={{
          color: 'primary',
        }}
        onClick={onClick}
        {...rest}
      >
        {children}
      </Button>
    </Flex>
  );
}

export default MenuLink;
