import { HStack, StackProps } from '@chakra-ui/react';
import React, { FC, memo } from 'react';

import Money from 'components/shared/money';
import { ProductPricingField_pricing } from 'lib/graphql/types/ProductPricingField';

export interface ProductPricingRangeProps extends StackProps {
  pricing: ProductPricingField_pricing;
}

const ProductPricingRange: FC<ProductPricingRangeProps> = ({ pricing, ...rest }) => {
  return (
    <HStack spacing={2} {...rest}>
      <Money money={pricing.priceRangeUndiscounted.start} strike={pricing.onSale} />
      {pricing.onSale && <Money money={pricing.priceRange.start} />}
    </HStack>
  );
};

ProductPricingRange.displayName = 'ProductPricingRange';

export default memo(ProductPricingRange);
