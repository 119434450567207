import { Category, Post } from 'sanity-schema';
import { Product } from 'types';
import { getLocalizedLink } from './links';

export function categoryUrl(category: { slug: string; translation?: { slug: string } }) {
  return `/${category.translation?.slug || category.slug}`;
}

export function getCategoryUrlBySlug(slug: string) {
  return `/${slug}`;
}

export function productUrl(product: Product) {
  return `/shop/${product.translation?.slug || product.slug}`;
}

export function getProductUrlBySlug(slug: string) {
  return `/shop/${slug}`;
}

export function newsCategoryUrl(category: Category, locale: string) {
  return `${getLocalizedLink('newsCategory', locale)}/${
    category.slug?.[locale]?.current as string
  }`;
}

export function getNewsCategoryUrlBySlug(slug: string, locale: string) {
  return `${getLocalizedLink('newsCategory', locale)}/${slug}`;
}

export function newsPostUrl(post: Post, locale: string) {
  return `${getLocalizedLink('news', locale)}/${post.slug?.[locale]?.current as string}`;
}

export function getNewsPostUrlBySlug(slug: string, locale: string) {
  return `${getLocalizedLink('news', locale)}/${slug}`;
}
