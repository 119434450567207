import { useCallback, useState } from 'react';

type MutationFunction<T extends unknown[], R = unknown> = (...args: T) => Promise<R>;

export function useMutationFunction<
  T extends unknown[],
  R extends { error?: unknown; data?: unknown } = unknown
>(fn: MutationFunction<T, R>) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<R extends { error?: infer E } ? E : never>();

  const mutate: typeof fn = useCallback(
    async (...params) => {
      setLoading(true);
      setSuccess(false);
      try {
        const result = await fn(...params);
        setLoading(false);

        if ((Array.isArray(result?.error) && result.error?.length) || result?.error) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setError(result.error as any);
        } else {
          setSuccess(true);
        }

        return result;
      } catch (e) {
        setLoading(false);
        throw e;
      }
    },
    [fn],
  );

  return { mutate, loading, error, success };
}
