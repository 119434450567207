export default function useItemTranslation() {
  function localeField<T extends { translation: object }>(
    item: T,
    key: Exclude<keyof T, 'translation'>,
  ): string {
    return (item.translation?.[key as string] || item[key]) as string;
  }

  return { localeField };
}
