function Gmaps() {
  return (
    <iframe
      title="gmaps"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2969.246608885243!2d12.475588876428626!3d41.90905706347473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f6b0f442148b1%3A0xc667523410afb82e!2sHype%20Clothinga!5e0!3m2!1sit!2sit!4v1683187153352!5m2!1sit!2sit"
      width="100%"
      height="100%"
      style={{ border: 0 }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
}

export default Gmaps;
