import isString from 'lodash/isString';
import { useEffect, useState } from 'react';

type Size = { width: number | string; height: number | string };
type SizeNumber = { width: number; height: number };

function getSizeNumber(size: number | string, offset?: number) {
  if (isString(size)) {
    return size;
  }

  return size + (offset ?? 0);
}

// Hook
export function useWindowSize(
  initial: Partial<Size> = { width: undefined, height: undefined },
  offset: Partial<SizeNumber> = { width: 0, height: 0 },
) {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>(
    typeof window !== 'undefined'
      ? {
          width:
            initial?.width !== undefined
              ? getSizeNumber(initial.width, offset.width)
              : window.innerWidth + offset.width,
          height:
            initial?.height !== undefined
              ? getSizeNumber(initial.height, offset.height)
              : window.innerHeight + offset.height,
        }
      : {
          width:
            initial?.width !== undefined ? getSizeNumber(initial.width, offset.width) : undefined,
          height:
            initial?.height !== undefined ? getSizeNumber(initial.height, offset.width) : undefined,
        },
  );

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth + offset.width,
        height: window.innerHeight + offset.height,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
