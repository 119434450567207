import { Box, Flex, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { FC, useCallback } from 'react';
import { IoMdClose } from 'react-icons/io';

import Image from 'components/image';
import Link from 'components/link';
import Money from 'components/shared/money';
import useItemTranslation from 'hooks/useItemTranslation';
import { CartModel } from 'models/Cart';

interface CartItemProps {
  item: CartModel['items'][0];
  noBorder?: boolean;
  onRemove(variantId: string): void;
}

const CartItem: FC<CartItemProps> = ({ item, onRemove, noBorder = false }) => {
  const { t } = useTranslation('common');
  const { localeField } = useItemTranslation();
  const handleRemove = useCallback(() => {
    onRemove(item.variant.id);
  }, [onRemove, item.variant.id]);

  return (
    <HStack
      spacing={3}
      mt={5}
      pt={noBorder ? 0 : 5}
      borderTop={noBorder ? 'none' : '1px'}
      borderColor="darkGray.100"
      align="stretch"
    >
      {item.variant.product.thumbnail && (
        <Link href={`/shop/${item.variant.product.slug}`} variant="unstyled" alignSelf="center">
          <Image
            unoptimized
            src={item.variant.product.thumbnail.url}
            alt={item.variant.product.thumbnail.alt}
            width={96}
            height={96}
            w={24}
            h={24}
            objectFit="contain"
          />
        </Link>
      )}
      <Flex direction="column" flex={1}>
        <Flex direction="row">
          <Link flex={1} href={`/shop/${item.variant.product.slug}`} display="inline-block" mb={2}>
            {item.variant.product.translation?.name ?? item.variant.product.name}
          </Link>
          <Flex alignSelf="flex-start">
            <IconButton
              icon={<IoMdClose size="14px" />}
              aria-label="Rimuovi"
              variant="iconOutline"
              w="14px"
              h="20px"
              onClick={handleRemove}
            />
          </Flex>
        </Flex>
        <Box>
          <VStack align="flex-start" spacing={0}>
            {item.variant.attributes?.map((attribute) => (
              <Flex
                key={attribute.attribute.id}
                w="full"
                fontSize="sm"
                color="grayText"
                justify="space-between"
              >
                <Text as="span">{localeField(attribute.attribute, 'name')}: </Text>
                <Text as="span">{attribute.values.map((value) => value.value).join(',')}</Text>
              </Flex>
            ))}
            {item.data?.map((itemData) => (
              <Flex
                key={itemData.key}
                w="full"
                color="grayText"
                fontSize="sm"
                justify="space-between"
              >
                <Text as="span">{itemData.key}:</Text>
                <Text as="span">{itemData.value}</Text>
              </Flex>
            ))}
            <Flex w="full" fontSize="sm" color="grayText" justify="space-between">
              <Text as="span">{t('cart.quantity')}:</Text>
              <Text as="span">{item.quantity}</Text>
            </Flex>
          </VStack>
          <Flex w="full" justify="flex-end">
            <Money money={item.totalPrice} color="black" fontWeight="normal" mt={2} />
          </Flex>
        </Box>
      </Flex>
    </HStack>
  );
};

export default CartItem;
