import { SlideFade } from '@chakra-ui/react';
import { SlideFadeProps } from '@chakra-ui/transition';
import { Variant } from 'framer-motion';
import React, { FC } from 'react';

export type MotionVariants<T extends string> = Record<T, Variant>;

type SlideFadeVariant = MotionVariants<'initial' | 'enter' | 'exit'>;

export const EASINGS = {
  ease: [0.25, 0.1, 0.25, 1],
  easeIn: [0.4, 0, 1, 1],
  easeOut: [0, 0, 0.2, 1],
  easeInOut: [0.4, 0, 0.2, 1],
};

const transitions = {
  enter: {
    duration: 0.2,
    ease: EASINGS.easeOut,
  },
  exit: {
    duration: 0.1,
    ease: EASINGS.easeIn,
  },
};

const variants: SlideFadeVariant = {
  initial: (props) => ({
    opacity: 0,
    visibility: 'hidden',
    x: props.offsetX,
    y: props.offsetY,
    transition: transitions.exit,
  }),
  exit: (props) => ({
    opacity: 0,
    visibility: 'hidden',
    transition: transitions.exit,
    ...(props.reverse && {
      x: props.offsetX,
      y: props.offsetY,
    }),
    ...(!props.reverse && {
      transitionEnd: {
        x: props.offsetX,
        y: props.offsetY,
      },
    }),
  }),
  enter: {
    opacity: 1,
    visibility: 'visible',
    x: 0,
    y: 0,
    transition: transitions.enter,
  },
};

const VisibilitySlideFade: FC<SlideFadeProps> = ({ children, ...props }) => {
  return (
    <SlideFade variants={variants} {...props}>
      {children}
    </SlideFade>
  );
};

export default VisibilitySlideFade;
