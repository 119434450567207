import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbProps,
  Flex,
  Heading,
  Image,
  StackProps,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { FaAngleRight } from 'react-icons/fa';

import Link from 'components/next-link';

export interface HeroProps {
  breadcrumbs?: {
    href: string;
    name: string;
    as?: string;
    isCurrentPage?: boolean;
  }[];
  title: string;
  image?: string;
  stackProps?: StackProps;
  breadcrumbProps?: BreadcrumbProps;
}

function Hero({ title, breadcrumbs = [], image, stackProps, breadcrumbProps }: HeroProps) {
  return (
    <VStack
      spacing={4}
      py={[20, null, 24, 32]}
      bg="darkGray.100"
      justifyContent="center"
      position="relative"
      {...stackProps}
    >
      <Image
        unoptimized
        src={image || '/images/hero-background.jpg'}
        objectFit="cover"
        objectPosition="center"
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        w="full"
        h="full"
        zIndex={1}
        pointerEvents="none"
        filter={image ? 'brightness(0.5)' : ''}
      />

      <Flex
        direction="column"
        justify="center"
        align="center"
        zIndex="docked"
        color="lightText"
        px={8}
      >
        <Heading as="h1">{title}</Heading>

        {breadcrumbs.length > 0 && (
          <Breadcrumb
            className="hero-breadcrumb"
            separator={<FaAngleRight size="12px" />}
            {...breadcrumbProps}
          >
            {breadcrumbs.map((breadcrumb, i) => (
              <BreadcrumbItem
                key={i}
                alignItems="baseline"
                isCurrentPage={breadcrumb.isCurrentPage}
              >
                <Link href={breadcrumb.href} as={breadcrumb.as} passHref>
                  <BreadcrumbLink>{breadcrumb.name}</BreadcrumbLink>
                </Link>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        )}
      </Flex>
    </VStack>
  );
}

export default React.memo(Hero);
