import {
  Box,
  BoxProps,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  Link as ChakraLink,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Squash as Hamburger } from 'hamburger-react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useIsomorphicLayoutEffect, useWindowScroll } from 'react-use';

import Bag from 'components/i/Bag';
import Diamond from 'components/i/Diamond';
import Diamond2 from 'components/i/Diamond2';
import Magnifier from 'components/i/Magnifier';
import User from 'components/i/User';
import AccountModal from 'components/modals/account-modal';
import NextLink from 'components/next-link';
import SearchAutocomplete from 'components/search-autocomplete/search-autocomplete';
import { useUI } from 'hooks';
import { useAuth } from 'hooks/auth';
import { useWindowSize } from 'hooks/useWindowSize';
import { getMenu, getMobileMenu } from 'lib/menu';
import CartDrawer from './cart-drawer';
import MobileMenu from './mobile-menu';
import Nav from './nav';

export interface HeaderProps {
  transparent?: boolean;
  wishlistHasProducts?: boolean;
  hideSearch?: boolean;
  bg?: BoxProps['bg'];
}

interface MenuIconButtonProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  color?: string;
  icon: React.ReactElement;
  'aria-label': string;
}

const MenuIconButton = React.forwardRef<HTMLButtonElement, MenuIconButtonProps>(
  ({ onClick, icon, color, ...props }, ref) => {
    return (
      <IconButton
        as={ChakraLink}
        ref={ref}
        icon={React.cloneElement(icon, {
          size: '24px',
          width: '24px',
          height: '24px',
          fill: color,
        })}
        aria-label={props['aria-label']}
        h="auto"
        minW="auto"
        isRound={false}
        variant="unstyled"
        _hover={{
          color: 'none',
        }}
        onClick={onClick}
        {...props}
      />
    );
  },
);

const SearchIcon: FC<{ onClick?(): void; onHide?(): void }> = ({ onClick, onHide }) => {
  const { y } = useWindowScroll();
  const [hidden, setHidden] = useState(y < 550);

  useEffect(() => {
    if (y < 550) {
      if (!hidden) {
        setHidden(true);
        onHide?.();
      }
    } else if (hidden) {
      setHidden(false);
    }
  }, [hidden, y, onHide]);

  if (hidden) {
    return null;
  }

  return (
    <MenuIconButton icon={<Magnifier />} aria-label="Search" color="black" onClick={onClick} />
  );
};

const MobileSearch: FC<{ onClose?(): void }> = ({ onClose }) => {
  const searchRef = useRef<HTMLInputElement>();
  const { height } = useWindowSize({ height: 'calc(100vh - 55px)' }, { height: -55 });

  useIsomorphicLayoutEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef]);

  return (
    <SearchAutocomplete
      ref={searchRef}
      inputGroup={{ border: 'none', h: 'full', alignItems: 'center', bg: 'white' }}
      container={{ h: 'full' }}
      input={{ border: 'none', _focus: { boxShadow: 'none' } }}
      menu={{ style: { height } }}
      onInputClose={onClose}
      hideCloseButton={false}
      forceOpen
    />
  );
};

const Header = ({ bg = 'bodyBg', hideSearch }: HeaderProps) => {
  const { t, lang } = useTranslation('common');
  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSearchOpen,
    onToggle: onSearchToggle,
    onClose: onSearchClose,
    onOpen: onSearchOpen,
  } = useDisclosure({
    onOpen() {
      if (isMenuOpen) {
        closeMenu();
      }
    },
  });
  const { isCartOpen, openCart, closeCart, isMenuOpen, closeMenu, toggleMenu } = useUI();
  const { y } = useWindowScroll();
  const cartDrawerButtonRef = React.useRef<HTMLButtonElement>();
  const isMobile = useBreakpointValue({
    base: true,
    xl: false,
  });
  const showMobileSearch = useBreakpointValue({
    base: true,
    lg: false,
  });
  const auth = useAuth();

  useEffect(() => {
    if (!isMobile && isMenuOpen) {
      closeMenu();
    }
  }, [closeMenu, isMenuOpen, isMobile]);

  const wishlistHasProducts = auth.wishlist.productIds.length > 0;
  let hasVideo;
  let hasExternalImg;
  let negativeLogo = false;
  if (process.env.NEXT_PUBLIC_HERO_VIDEO_CONFIG) {
    const videoConfig = JSON.parse(process.env.NEXT_PUBLIC_HERO_VIDEO_CONFIG);
    const videoUrl = videoConfig[lang];
    negativeLogo = videoConfig.negativeLogo;
    hasVideo = Boolean(videoUrl && Object.keys(videoUrl).length) && router.pathname === '/';
  } else if (process.env.NEXT_PUBLIC_HERO_IMG_CONFIG) {
    const imgConfig = JSON.parse(process.env.NEXT_PUBLIC_HERO_IMG_CONFIG);
    const imgUrl = imgConfig[lang];
    negativeLogo = imgConfig.imgConfig;
    hasExternalImg = Boolean(imgUrl && Object.keys(imgUrl).length) && router.pathname === '/';
  } else {
    hasVideo = false;
  }
  const [useTransparentHeader, setUseTransparentHeader] = useState(hasVideo || hasExternalImg);
  const transparentHeaderThreshold = isMobile ? 20 : 50;
  const menu = getMenu(t);
  const mobileMenu = getMobileMenu(t);
  const color = 'black';
  negativeLogo = useTransparentHeader && negativeLogo;

  useEffect(() => {
    if (y > transparentHeaderThreshold) {
      setUseTransparentHeader(false);
    } else if (isMobile && isMenuOpen) {
      setUseTransparentHeader(false);
    } else {
      setUseTransparentHeader(hasVideo || hasExternalImg);
    }
  }, [y, isMenuOpen, isMobile]);

  return (
    <Box
      as="header"
      h="auto"
      top="0"
      position="fixed"
      className={
        useTransparentHeader && negativeLogo
          ? `header--transparent header--white`
          : useTransparentHeader && !negativeLogo
          ? `header--transparent`
          : `header--plain`
      }
      w="full"
      zIndex="dropdown"
      transitionProperty={['position', 'colors']}
      transitionDuration="slow"
      bg={bg}
      boxShadow="lg"
    >
      <Container
        position="relative"
        maxW="container.3xl"
        px={isMobile && isSearchOpen ? 0 : 4}
        h={{ base: '58px', lg: '88px' }}
        alignItems="center"
        justifyContent="center"
        centerContent
        borderBottom={isMenuOpen ? '1px' : 'none'}
        borderColor="darkGray.100"
      >
        <Flex w="full" h="full" align="center" justify="space-between">
          {isMobile && isSearchOpen ? (
            <MobileSearch onClose={onSearchClose} />
          ) : (
            <>
              {isMobile && (
                <>
                  <Box ml="-12px">
                    <Hamburger toggled={isMenuOpen} toggle={toggleMenu} size={20} hideOutline />
                  </Box>
                </>
              )}
              <Box flex={isMobile ? 1 : '0 0 16.666667%'} maxW={!isMobile && '16.666667%'}>
                <Box maxW={['120px', '120px', '175px']}>
                  <NextLink href="/" passHref>
                    <ChakraLink display="block">
                      {negativeLogo && (
                        <Image src="/images/logo-abbigliamento-edizione-limitata-hype-clothinga-store.png" />
                      )}
                      {!negativeLogo && (
                        <Image src="/images/logo-abbigliamento-edizione-limitata-hype-clothinga-store-black.png" />
                      )}
                    </ChakraLink>
                  </NextLink>
                </Box>
              </Box>
              {!isMobile && router.isReady && (
                <Nav
                  menu={menu}
                  bg={bg}
                  isSearchOpen={isSearchOpen}
                  onSearchClose={onSearchClose}
                />
              )}
              <Box
                flex={!isMobile && '0 0 16.666667%'}
                maxW={!isMobile && '16.666667%'}
                w={isMobile && '70px'}
              >
                <HStack spacing={4} justifyContent="flex-end">
                  {showMobileSearch ? (
                    <MenuIconButton
                      icon={<Magnifier />}
                      aria-label="Search"
                      color="black"
                      onClick={onSearchOpen}
                    />
                  ) : (
                    <>
                      {hideSearch ? (
                        <SearchIcon onClick={onSearchToggle} onHide={onSearchClose} />
                      ) : (
                        <MenuIconButton
                          icon={<Magnifier />}
                          aria-label="Search"
                          color="black"
                          onClick={onSearchToggle}
                        />
                      )}
                      {auth.isAuthenticated ? (
                        <NextLink href="/account" passHref>
                          <MenuIconButton icon={<User />} aria-label="Account" color={color} />
                        </NextLink>
                      ) : (
                        <MenuIconButton
                          icon={<User />}
                          aria-label="Account"
                          color={color}
                          onClick={onOpen}
                        />
                      )}
                      <AccountModal isOpen={!auth.isAuthenticated && isOpen} onClose={onClose} />
                    </>
                  )}
                  <NextLink key="wishlistFull" href="/wishlist" passHref>
                    <MenuIconButton
                      icon={wishlistHasProducts ? <Diamond2 /> : <Diamond />}
                      aria-label="Whishlist"
                      color={color}
                    />
                  </NextLink>
                  <MenuIconButton
                    ref={cartDrawerButtonRef}
                    icon={<Bag />}
                    aria-label="Cart"
                    color={color}
                    onClick={openCart}
                  />
                </HStack>
                <CartDrawer
                  isOpen={isCartOpen}
                  onClose={closeCart}
                  finalFocusRef={cartDrawerButtonRef}
                />
              </Box>
            </>
          )}
        </Flex>
      </Container>
      {isMobile && <MobileMenu isOpen={isMenuOpen} onClose={closeMenu} items={mobileMenu} />}
    </Box>
  );
};

export default observer(Header);
