import { LinkProps } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { MouseEventHandler, PropsWithChildren } from 'react';

import Link from 'components/link';

interface MenuLinkProps extends LinkProps {
  href: string;
  noBorder?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  noFlex?: boolean;
}

function MenuLink({
  href,
  children,
  onClick,
  noBorder = false,
  noFlex = false,
  ...rest
}: PropsWithChildren<MenuLinkProps>) {
  const router = useRouter();

  return (
    <Link
      href={href}
      variant={`${router.asPath === href ? 'mobileNavActive' : 'mobileNav'}`}
      flex={noFlex ? 0 : 1}
      borderBottom={`${noBorder ? 'none' : '1px'}`}
      borderBottomColor="darkGray.100"
      w="full"
      onClick={onClick}
      {...rest}
    >
      {children}
    </Link>
  );
}

export default MenuLink;
