/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  Heading,
  IconButton,
  LinkProps,
  Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import useTranslation from 'next-translate/useTranslation';
import React, { FC, useCallback } from 'react';
import { IoMdClose } from 'react-icons/io';

import CartItem from 'components/cart/cart-item';
import Link from 'components/link';
import Money from 'components/shared/money';
import { useCart } from 'hooks/cart';
import DrawerContent from './drawer-content';

interface CartButtonProps extends LinkProps {
  href: string;
}

const CartButton: FC<CartButtonProps> = ({ href, children, ...rest }) => {
  return (
    <Link
      href={href}
      flex={1}
      variant="buttonDark"
      border="none"
      textAlign="center"
      textTransform="uppercase"
      letterSpacing="wider"
      color="white"
      bg="black"
      m={0}
      _hover={{ bg: 'primary', color: 'white' }}
      _focus={{ bg: 'primary', color: 'white' }}
      {...rest}
    >
      {children}
    </Link>
  );
};

const CartDrawer: FC<Pick<DrawerProps, 'isOpen' | 'onClose' | 'finalFocusRef'>> = ({
  isOpen,
  onClose,
  finalFocusRef,
}) => {
  const cart = useCart();
  const { t } = useTranslation('common');

  const handleRemove = useCallback(
    async (variantId: string) => {
      await cart.removeItem(variantId);
    },
    [cart],
  );

  return (
    <Drawer
      placement="right"
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={finalFocusRef}
      motionPreset="none"
    >
      <DrawerOverlay>
        <DrawerContent bgColor="darkGray.50">
          <DrawerHeader p={0} borderBottom="1px" borderBottomColor="darkGray.100">
            <Flex align="center">
              <Flex
                borderRight="1px"
                borderColor="darkGray.100"
                w={12}
                h={12}
                align="center"
                justify="center"
              >
                <Text fontWeight="semibold">{cart.items.length}</Text>
              </Flex>
              <Heading flex={1} fontSize="2xl" textAlign="center">
                {t('cart.cart')}
              </Heading>
              <IconButton
                aria-label="Close"
                variant="iconOutline"
                icon={<IoMdClose size="24px" />}
                borderLeft="1px"
                borderColor="darkGray.100"
                w={12}
                h={12}
                onClick={onClose}
              />
            </Flex>
          </DrawerHeader>
          <DrawerBody pt={0} px={8}>
            {cart.items.map((item, i) => (
              <CartItem
                key={item?.id || `${i}:${item.variant.id}`}
                item={item}
                noBorder={i === 0}
                onRemove={handleRemove}
              />
            ))}
          </DrawerBody>
          <DrawerFooter p={0} display="flex" flexDirection="column" alignItems="stretch">
            <Flex bgColor="white" px={8} py={5}>
              <Text flex={1}>{t('cart.total')}:</Text>
              <Money money={cart.totalPricing.subtotalPrice} />
            </Flex>
            <Flex>
              <CartButton href="/cart" bg="darkGray.800" onClick={onClose}>
                {t('cart.cart')}
              </CartButton>
              <CartButton href="/checkout" onClick={onClose}>
                {t('cart.checkout')}
              </CartButton>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default observer(CartDrawer);
