import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Slide,
  Text,
  TextProps,
  useDisclosure,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import React, { FC, MouseEventHandler, ReactNode } from 'react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import { RemoveScroll } from 'react-remove-scroll';

import User from 'components/i/User';
import MenuLink from 'components/user/menu-link';
import { useLocalizedLink } from 'hooks/useLocalizedLink';
import { MenuItem } from 'types';
import AccountTab from './account';

export interface MenuTabProps {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  items?: MenuItem[];
  isOpen: boolean;
}

export interface DropdownLinkProps {
  item: MenuItem;
  locale: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export interface DropdownLinkContentProps {
  name: string | ReactNode;
  buttonProps?: ButtonProps;
  buttonTextProps?: TextProps;
}

const DropdownLinkContent: FC<DropdownLinkContentProps> = ({
  name,
  buttonProps,
  buttonTextProps,
  children,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Box>
      <Button variant="link" layerStyle="mobileNavLink" onClick={onToggle} {...buttonProps}>
        <Text as="span" flex={1} textAlign="left" {...buttonTextProps}>
          {name}
        </Text>
        <BiRightArrowAlt style={{ display: 'inline', width: '1.25rem', height: '1.25rem' }} />
      </Button>
      <Slide
        direction="right"
        in={isOpen}
        style={{
          position: 'absolute',
          zIndex: 1600,
        }}
      >
        <Box position="absolute" h="full" w="full" bg="bodyBg">
          <Flex direction="column" h="full" overflowY="scroll">
            <Button
              variant="link"
              layerStyle="mobileNavLink"
              bg="primary"
              color="white"
              style={{
                display: 'inline-flex',
                justifyContent: 'flex-start',
              }}
              _hover={{ color: 'white' }}
              _active={{ color: 'white' }}
              onClick={onClose}
            >
              <BiLeftArrowAlt style={{ display: 'inline', width: '1.25rem', height: '1.25rem' }} />
              <Text as="span" ml={2}>
                {name}
              </Text>
            </Button>
            {children}
          </Flex>
        </Box>
      </Slide>
    </Box>
  );
};

const DropdownLink: FC<DropdownLinkProps> = ({ item, onClick, locale }) => {
  return (
    <DropdownLinkContent name={item.name}>
      {item.children?.map((item, index) =>
        item.children?.length > 0 ? (
          <DropdownLink key={index} item={item} onClick={onClick} locale={locale} />
        ) : item.href || item.hrefEn ? (
          <MenuLink
            key={index}
            href={locale === 'en' ? item.hrefEn : item.href}
            onClick={onClick}
            layerStyle="mobileNavLink"
            noFlex
          >
            {item.name}
          </MenuLink>
        ) : null,
      )}
    </DropdownLinkContent>
  );
};

function MenuTab({ onClick, items, isOpen }: MenuTabProps) {
  const localizeLink = useLocalizedLink();
  const { locale } = useRouter();
  const { t } = useTranslation('common');

  return (
    <RemoveScroll enabled={isOpen} forwardProps>
      <Flex direction="column" h="full" position="relative">
        <Flex direction="column" flex={1} overflow="hidden">
          <Box as="nav" overflowY="scroll">
            {items?.map((item, index) =>
              item.children?.length > 0 ? (
                <DropdownLink key={index} item={item} onClick={onClick} locale={locale} />
              ) : item.href || item.hrefEn ? (
                <MenuLink
                  key={index}
                  href={locale === 'en' ? item.hrefEn : item.href}
                  onClick={onClick}
                  layerStyle="mobileNavLink"
                  noFlex
                >
                  {item.name}
                </MenuLink>
              ) : null,
            )}
            <MenuLink
              href={localizeLink('news')}
              onClick={onClick}
              layerStyle="mobileNavLink"
              noFlex
            >
              {t('menu.news')}
            </MenuLink>
          </Box>
        </Flex>
        <Box bottom={0} borderTop="1px" w="full" borderColor="darkGray.100">
          <DropdownLinkContent
            name={
              <Flex align="center">
                <User fill="currentColor" width="0.75em" height="0.75em" />{' '}
                <Text as="span" ml={2}>
                  Account
                </Text>
              </Flex>
            }
            buttonProps={{ bg: 'darkBackground' }}
            buttonTextProps={{ fontWeight: 'normal', fontSize: 'lg' }}
          >
            <AccountTab onClick={onClick} />
          </DropdownLinkContent>
        </Box>
      </Flex>
    </RemoveScroll>
  );
}

export default MenuTab;
