import { Image, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

import Link from 'components/link';

export interface ImageLinkProps {
  href: string;
  name: string;
  src: string;
  alt?: string;
}

const ImageLink: FC<ImageLinkProps> = ({ href, name, src, alt }) => {
  return (
    <Link
      href={href}
      role="group"
      sx={{
        '&:hover .image-link-text::after': {
          left: 0,
          w: 'full',
        },
      }}
    >
      <Image unoptimized src={src} alt={alt || name} maxH="250px" />
      <Text
        mt={4}
        as="span"
        display="inline-flex"
        fontSize="lg"
        fontWeight="semibold"
        position="relative"
        className="image-link-text"
        w="auto"
        _after={{
          content: '""',
          borderBottom: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'black',
          position: 'absolute',
          right: 0,
          width: 0,
          bottom: 0,
          transition: 'width .4s linear 0s',
          w: 0,
        }}
        _active={{
          color: 'black',
        }}
        _focus={{
          color: 'black',
        }}
        _hover={{
          color: 'black',
        }}
        _groupHover={{
          color: 'black',
        }}
      >
        {name}
      </Text>
    </Link>
  );
};

export default ImageLink;
