import { Text, TextProps } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { memo, useMemo } from 'react';

import { useShop } from 'hooks';
import { Price, PriceValue } from 'types';

interface MoneyProps extends TextProps {
  money?: Price;
  strike?: boolean;
  gray?: boolean;
}

function Money({ money, strike = false, gray = false, size = 'sm', ...rest }: MoneyProps) {
  const router = useRouter();
  const { displayGrossPrices, displayPrice, defaultCurrency } = useShop();
  const priceValue = useMemo(() => {
    let price: PriceValue = null;
    if (money) {
      price = displayGrossPrices ? money.gross : money.net;
    } else {
      price = {
        currency: defaultCurrency,
        amount: 0,
      };
    }

    return displayPrice(price.amount, router.locale);
  }, [defaultCurrency, displayGrossPrices, displayPrice, money, router.locale]);

  return (
    <Text
      as="span"
      color={strike || gray ? 'darkGray.500' : 'black'}
      fontWeight={{ base: 'semibold', lg: 'bold' }}
      fontSize={size}
      textDecoration={strike ? 'line-through' : ''}
      whiteSpace="nowrap"
      {...rest}
    >
      {priceValue}
    </Text>
  );
}

export default memo(Money);
