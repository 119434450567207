import { NextRouter } from 'next/router';
import normalizeUrl from 'normalize-url';

import { pageLinks } from 'lib/links';
import { LocalePaths } from 'types';

export function buildUrl(...paths: string[]): string {
  return normalizeUrl(`${process.env.NEXT_PUBLIC_SHOP_URL}/${paths.filter(Boolean).join('/')}`);
}

export function getLocalizedCurrentUrl(
  router: NextRouter,
  locale: string,
  localePaths?: LocalePaths,
): string {
  let destinationPath = '';
  const localePath = localePaths?.find((path) => path.locale === locale)?.path;
  const localePage: string = Object.values(pageLinks).find((page) =>
    Object.values(page).includes(router.pathname),
  )?.[locale];

  if (localePage) {
    destinationPath = localePage;
  } else if (localePath) {
    destinationPath = localePath;
  }

  if (destinationPath) {
    const query =
      router.asPath.indexOf('?') !== -1 ? router.asPath.substr(router.asPath.indexOf('?')) : '';
    return `${destinationPath}${query}`;
  } else {
    return router.asPath;
  }
}
