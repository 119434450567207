import { Box, Flex, Heading } from '@chakra-ui/react';
import React, { forwardRef, memo } from 'react';

import Image from 'components/image';
import ProductPriceRange from 'components/product/product-price-range';
import useItemTranslation from 'hooks/useItemTranslation';
import { ProductSearch_products_edges_node } from 'lib/graphql/types/ProductSearch';

export interface SearchProductItemProps {
  product: ProductSearch_products_edges_node;
  highlighted?: boolean;
}

const SearchProductItem = forwardRef<HTMLDivElement, SearchProductItemProps>(
  ({ product, highlighted, ...rest }, ref) => {
    const { localeField } = useItemTranslation();

    return (
      <Flex
        ref={ref}
        p={4}
        cursor="pointer"
        bg={highlighted ? 'darkGray.100' : 'transparent'}
        _hover={{
          bg: 'darkGray.100',
        }}
        _focus={{
          bg: 'darkGray.100',
        }}
        {...rest}
      >
        <Image
          unoptimized
          src={product.thumbnail2x.url}
          alt={product.thumbnail.alt}
          width={64}
          height={64}
          objectFit="contain"
        />
        <Box ml={4}>
          <Heading as="h4" fontSize="md">
            {localeField(product, 'name')}
          </Heading>
          {product.pricing?.priceRangeUndiscounted && (
            <ProductPriceRange pricing={product.pricing} mt={2} />
          )}
        </Box>
      </Flex>
    );
  },
);

SearchProductItem.displayName = 'SearchProductItem';

export default memo(SearchProductItem);
