import * as React from 'react';

function SvgRuler(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      ref={svgRef}
      {...props}
    >
      <path d="M17.5 7h-16C.673 7 0 7.673 0 8.5v3c0 .827.673 1.5 1.5 1.5h16c.827 0 1.5-.673 1.5-1.5v-3c0-.827-.673-1.5-1.5-1.5zm.5 4.5a.5.5 0 01-.5.5H16v-1.5a.5.5 0 00-1 0V12h-2V9.5a.5.5 0 00-1 0V12h-2v-1.5a.5.5 0 00-1 0V12H7V9.5a.5.5 0 00-1 0V12H4v-1.5a.5.5 0 00-1 0V12H1.5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h16a.5.5 0 01.5.5v3z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRuler);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
