import { gql } from '@apollo/client';

export const categoryPathsQuery = gql`
  query CategoryPathsQuery($first: Int!, $after: String) {
    categories(first: $first, after: $after) {
      edges {
        node {
          slug
          en: translation(languageCode: EN) {
            slug
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const categoriesQuery = gql`
  query Categories($filter: CategoryFilterInput) {
    categories(first: 100, filter: $filter, sortBy: { field: NAME, direction: ASC }) {
      edges {
        node {
          id
          name
          slug
          translation {
            name
            slug
          }
        }
      }
    }
  }
`;

export const categoriesSearchQuery = gql`
  query CategoriesSearch($search: String!) {
    categories(first: 5, filter: { productSearch: $search }, level: 0) {
      edges {
        node {
          id
          name
          slug
          translation {
            name
            slug
          }
          totalProducts(search: $search)
        }
      }
    }
  }
`;

export const categoriesWithChildrenQuery = gql`
  query CategoriesWithChildren($filter: CategoryFilterInput, $level: Int) {
    categories(
      first: 100
      filter: $filter
      level: $level
      sortBy: { field: NAME, direction: ASC }
    ) {
      edges {
        node {
          id
          name
          slug
          translation {
            name
            slug
          }
          children(first: 100) {
            edges {
              node {
                id
                name
                slug
                translation {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const allCategoriesQuery = gql`
  query AllCategories($parentId: ID, $level: Int) {
    allCategories(parentId: $parentId, level: $level) {
      name
      slug
      translation {
        name
        slug
      }
    }
  }
`;

export const categoryDetailsQuery = gql`
  query CategoryDetailsQuery($slug: String!) {
    category(slug: $slug) {
      id
      name
      description
      descriptionJson
      seoTitle
      seoDescription
      slug
      root {
        id
      }
      backgroundImage {
        alt
        url
      }
      socialImage: backgroundImage(width: 1200, height: 630) {
        alt
        url
      }
      translation {
        name
        description
        descriptionJson
        seoTitle
        seoDescription
      }
      translations {
        slug
        language {
          code
        }
      }
      ancestors(first: 100) {
        edges {
          node {
            slug
            name
            translation {
              name
              slug
            }
          }
        }
      }
    }
  }
`;
