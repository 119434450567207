import { Box, BoxProps, Container, ContainerProps, LinkProps } from '@chakra-ui/react';
import omit from 'lodash/omit';
import React, { FC } from 'react';

import NavLink from './nav-link';

export interface NavDropdownProps {
  link: {
    href: string;
    name: string;
  } & LinkProps;
  dropdown?: BoxProps;
  dropdownContainer?: ContainerProps;
}

const NavDropdown: FC<NavDropdownProps> = ({ link, dropdown, dropdownContainer, children }) => {
  return (
    <Box position="relative" role="group" className={'menu__top-item'}>
      <NavLink {...omit(link, 'name')}>{link.name}</NavLink>
      <Box
        bg="white"
        py={10}
        visibility="hidden"
        position="fixed"
        w="full"
        left={0}
        mt={5}
        transition="150ms linear"
        opacity={0}
        transform="translateY(5px)"
        _groupHover={{
          visibility: 'visible',
          opacity: 1,
          transform: 'translateY(0)',
        }}
        zIndex="popover"
        boxShadow="lg"
        {...dropdown}
      >
        <Container maxW="container.2xl" w="full" centerContent {...dropdownContainer}>
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default NavDropdown;
