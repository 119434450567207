import { Button, FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import GqlErrorsAlert from 'components/errors/gql-errors-alert';
import StrikeText from 'components/strike-text';
import { useAuth } from 'hooks/auth';
import { useMutationFunction } from 'hooks/useMutationFunction';
import { TokenAuthVariables } from 'lib/graphql/types/TokenAuth';

interface LoginFormProps {
  onRouteChange?(route: string): void;
  registerLink?: boolean;
  fullWidthButton?: boolean;
  onSubmit?(): void;
}

function LoginForm({
  onRouteChange,
  registerLink = false,
  fullWidthButton = true,
  onSubmit: onSubmitCallback,
}: LoginFormProps) {
  const { register, handleSubmit } = useForm<TokenAuthVariables>();
  const auth = useAuth();
  const { mutate: signIn, loading, error } = useMutationFunction(auth.signIn);
  const { t } = useTranslation('common');

  const handleRegister = useCallback(() => {
    onRouteChange?.('register');
  }, [onRouteChange]);
  const handleLostPassword = useCallback(() => {
    onRouteChange?.('reset-password');
  }, [onRouteChange]);

  const onSubmit: SubmitHandler<TokenAuthVariables> = async (data) => {
    const { error } = await signIn(data.email, data.password);

    if (!error) {
      if (onSubmitCallback) {
        onSubmitCallback();
        return;
      }
      onRouteChange?.('authenticated');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4}>
        <GqlErrorsAlert errors={error} />

        <FormControl id="login-email" isRequired>
          <FormLabel fontSize="sm">{t('email')}</FormLabel>
          <Input type="email" {...register('email')} required />
        </FormControl>
        <FormControl id="login-password" isRequired>
          <FormLabel fontSize="sm">{t('password')}</FormLabel>
          <Input type="password" {...register('password')} required />
        </FormControl>

        <Button
          variant="link"
          fontSize="xs"
          alignSelf="flex-start"
          color="darkGray.500"
          _hover={{ color: 'darkGray.500' }}
          onClick={handleLostPassword}
        >
          {t('login.forgotPassword')}
        </Button>

        <Button
          type="submit"
          variant="reversed"
          w={fullWidthButton ? 'full' : 'auto'}
          isLoading={loading}
        >
          {t('login.enter')}
        </Button>

        {registerLink && (
          <>
            <StrikeText>{t('or')}</StrikeText>

            <Button variant="link" onClick={handleRegister}>
              {t('login.register')}
            </Button>
          </>
        )}
      </VStack>
    </form>
  );
}

export default LoginForm;
