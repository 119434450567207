import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';

import ForgotPassword from 'components/forms/forgot-password';
import LoginForm from 'components/forms/login';
import RegisterForm from 'components/forms/register';

interface AccountModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function AccountModal({ isOpen, onClose }: AccountModalProps) {
  const { t } = useTranslation('common');
  const [route, setRoute] = useState('login');

  let visibleForm: React.ReactNode;
  let title: string;

  switch (route) {
    case 'register':
      visibleForm = <RegisterForm onRouteChange={setRoute} loginLink onSubmit={onClose} />;
      title = t('register.register');
      break;
    case 'reset-password':
      visibleForm = <ForgotPassword onRouteChange={setRoute} />;
      title = t('forgotPassword.restore');
      break;
    case 'login':
    default:
      visibleForm = <LoginForm onRouteChange={setRoute} registerLink onSubmit={onClose} />;
      title = t('login.enter');
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <ModalBody p={16} textAlign="center">
          <Heading as="h2" size="lg" fontWeight="regular" mb={6}>
            {title}
          </Heading>
          {visibleForm}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AccountModal;
