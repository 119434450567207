import { Box, Flex, Text } from '@chakra-ui/layout';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import React, { FC } from 'react';

import Ruler from 'components/i/Ruler';
import { useShop, useUI } from 'hooks';
import { CURRENCY_SYMBOLS } from 'lib/consts';
import { SettingsModal } from './settings-modal';

const SettingsWidget: FC = () => {
  const router = useRouter();
  const { currency, size } = useShop();
  const { t } = useTranslation('common');
  const { settings } = useUI();

  return (
    <>
      <Flex
        as="button"
        outline={0}
        w="full"
        py={2}
        px={4}
        border="1px"
        borderColor="darkGray.400"
        onClick={settings.onOpen}
      >
        <Box flex={1} textAlign="left" borderRight="1px" borderColor="darkGray.400">
          <Text as="span" fontSize="sm">
            {t(`languages.${router.locale}`)}
          </Text>
        </Box>
        <Flex
          flex={1}
          w="full"
          textAlign="center"
          justify="center"
          align="center"
          borderRight="1px"
          borderColor="darkGray.400"
        >
          <Text as="span" fontSize="sm" display="inline-block" mr={2}>
            <Ruler
              fill="currentColor"
              width="14px"
              height="14px"
              style={{ display: 'inline', marginTop: '-2px', transform: 'rotate(-45deg)' }}
            />
          </Text>
          <Text as="span" fontSize="sm">
            {size}
          </Text>
        </Flex>
        <Box flex={1} textAlign="right">
          <Text as="span" fontSize="sm" mr={2}>
            {CURRENCY_SYMBOLS[currency]}
          </Text>
          <Text as="span" fontSize="sm">
            {currency}
          </Text>
        </Box>
      </Flex>
      <SettingsModal isOpen={settings.isOpen} onClose={settings.onClose} />
    </>
  );
};

export default SettingsWidget;
