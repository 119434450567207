/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, BoxProps, Flex, Spacer, Spinner, VStack } from '@chakra-ui/react';
import { UseComboboxGetItemPropsOptions, UseComboboxGetMenuPropsOptions } from 'downshift';
import useTranslation from 'next-translate/useTranslation';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import useElementScroll, { isScrolledToBottom } from 'hooks/useElementScroll';
import { isProduct, SearchItem } from './search-autocomplete';
import SearchCategoryItem from './search-category-item';
import SearchProductItem from './search-product-item';

export interface SearchAutocompleteContentProps {
  items: SearchItem[];
  isOpen: boolean;
  loading: boolean;
  inputValue: string;
  highlightedIndex: number;
  hasMore: boolean;
  menu?: BoxProps;
  onFetchMore(): void;
  getItemProps(options: UseComboboxGetItemPropsOptions<SearchItem>): any;
  getMenuProps(options?: UseComboboxGetMenuPropsOptions): any;
}

const offset = 24;

const SearchAutocompleteContent = forwardRef<unknown, SearchAutocompleteContentProps>(
  (
    {
      items,
      isOpen,
      loading,
      inputValue,
      menu,
      highlightedIndex,
      hasMore,
      getItemProps,
      getMenuProps,
      onFetchMore,
    },
    ref,
  ) => {
    const { t } = useTranslation('common');
    const anchor = useRef<HTMLDivElement>();
    const scrollPosition = useElementScroll(anchor);
    const [calledForMore, setCalledForMore] = useState(false);
    const [debouncing, setDebouncing] = useState(false);

    useImperativeHandle(ref, () => ({
      setDebouncing(isDebouncing: boolean) {
        if (debouncing !== isDebouncing) {
          setDebouncing(isDebouncing);
        }
      },
    }));

    useEffect(() => {
      if (
        typeof window !== 'undefined' &&
        !calledForMore &&
        !loading &&
        anchor.current?.clientHeight > 0 &&
        onFetchMore &&
        isScrolledToBottom(anchor, scrollPosition, offset) &&
        items?.length > 0
      ) {
        onFetchMore();
        setCalledForMore(true);
      }
    }, [scrollPosition, calledForMore, onFetchMore, items, loading]);

    useEffect(() => {
      if (calledForMore && !loading) {
        setCalledForMore(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
      <Box
        position="absolute"
        w="full"
        h={isOpen ? (items?.length > 0 ? 'xl' : 'auto') : 0}
        overflowY="scroll"
        boxShadow="lg"
        zIndex="popover"
        transition="height 0.25s ease-in"
        bg="bodyBg"
        {...menu}
        {...getMenuProps({ ref: anchor })}
      >
        {isOpen && (
          <Box>
            {loading && items?.length === 0 && (
              <Flex justify="center" py={4}>
                <Spinner speed="0.6s" />
              </Flex>
            )}
            {!loading && !debouncing && inputValue && items?.length === 0 ? (
              <Flex justify="center" py={4}>
                {t('noResults')}
              </Flex>
            ) : (
              <VStack
                align="stretch"
                divider={<Spacer borderColor="darkGray.100" style={{ margin: '0' }} />}
              >
                {items?.map((item, index) =>
                  isProduct(item) ? (
                    <SearchProductItem
                      product={item}
                      highlighted={highlightedIndex === index}
                      {...getItemProps({ item, key: item.id, index })}
                    />
                  ) : (
                    <SearchCategoryItem
                      category={item}
                      highlighted={highlightedIndex === index}
                      {...getItemProps({ item, key: item.id, index })}
                    />
                  ),
                )}
              </VStack>
            )}
            {hasMore && (
              <Flex justify="center" py={4}>
                <Spinner speed="0.6s" />
              </Flex>
            )}
          </Box>
        )}
      </Box>
    );
  },
);

SearchAutocompleteContent.displayName = 'SearchAutocompleteContent';

export default SearchAutocompleteContent;
