import { Link, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import NextLink, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef } from 'react';

const NavLink = forwardRef<HTMLAnchorElement, Pick<LinkProps, 'href'> & ChakraLinkProps>(
  ({ href, children, ...rest }, ref) => {
    const router = useRouter();

    return (
      <NextLink href={href} passHref>
        <Link
          ref={ref}
          variant={`${router.asPath === href ? 'navActive' : 'nav'}`}
          textStyle="navItem"
          {...rest}
        >
          {children}
        </Link>
      </NextLink>
    );
  },
);

export default NavLink;
