import { useLazyQuery } from '@apollo/client';
import {
  Box,
  BoxProps,
  IconButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import type { DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import React, { forwardRef, KeyboardEventHandler, useRef } from 'react';

import Cross from 'components/i/Cross';
import Magnifier from 'components/i/Magnifier';
import useItemTranslation from 'hooks/useItemTranslation';
import { useLocalizedLink } from 'hooks/useLocalizedLink';
import { categoriesSearchQuery } from 'lib/graphql/queries/categories';
import { productSearchQuery } from 'lib/graphql/queries/products';
import {
  CategoriesSearch,
  CategoriesSearch_categories_edges_node,
  CategoriesSearchVariables,
} from 'lib/graphql/types/CategoriesSearch';
import {
  ProductSearch,
  ProductSearch_products_edges_node,
  ProductSearchVariables,
} from 'lib/graphql/types/ProductSearch';
import { categoryUrl, productUrl } from 'lib/urls';
import SearchAutocompleteContent from './search-autocomplete-content';

export type SearchItem = ProductSearch_products_edges_node | CategoriesSearch_categories_edges_node;
type SearchDebouncedFn = DebouncedFunc<(changes: UseComboboxStateChange<SearchItem>) => void>;

export interface SearchAutocompleteProps {
  onInputClose?(): void;
  hideCloseButton?: boolean;
  forceOpen?: boolean;
  input?: InputProps;
  inputGroup?: InputGroupProps;
  container?: BoxProps;
  menu?: BoxProps;
}

export function isCategory(item: SearchItem): item is CategoriesSearch_categories_edges_node {
  return item.__typename === 'Category';
}

export function isProduct(item: SearchItem): item is ProductSearch_products_edges_node {
  return item.__typename === 'Product';
}

const SearchAutocomplete = forwardRef<HTMLInputElement, SearchAutocompleteProps>(
  ({ onInputClose, hideCloseButton, forceOpen, input, inputGroup, container, menu }, ref) => {
    const { t } = useTranslation('common');
    const { localeField } = useItemTranslation();
    const router = useRouter();
    const localizedLink = useLocalizedLink();
    const oldInputValue = useRef<string>();
    const contentRef = useRef<{ setDebouncing(debouncing: boolean): void }>();
    const [fetchProducts, { loading, data, fetchMore }] = useLazyQuery<
      ProductSearch,
      ProductSearchVariables
    >(productSearchQuery, {
      notifyOnNetworkStatusChange: true,
    });
    const [fetchCategories, { loading: categoriesLoading, data: categoriesData }] = useLazyQuery<
      CategoriesSearch,
      CategoriesSearchVariables
    >(categoriesSearchQuery, {
      notifyOnNetworkStatusChange: true,
    });

    const products = data?.products?.edges?.map((edge) => edge.node) || [];
    const categories: SearchItem[] =
      categoriesData?.categories?.edges?.map((edge) => edge.node) || [];

    const items = categories.concat(products);

    const debounceInputValue = useRef<SearchDebouncedFn | null>(null);
    const setValidatedInputValue = (changes: UseComboboxStateChange<SearchItem>) => {
      const normalizedInputValue = changes.inputValue?.trim() ?? '';
      if (normalizedInputValue) {
        fetchProducts({
          variables: {
            first: 20,
            search: normalizedInputValue,
          },
        });
        fetchCategories({ variables: { search: normalizedInputValue } });
      }
      contentRef.current?.setDebouncing(false);
    };

    if (!debounceInputValue.current) {
      debounceInputValue.current = debounce(setValidatedInputValue, 300);
    }

    const handleInputValueChange = (changes: UseComboboxStateChange<SearchItem>) => {
      oldInputValue.current = changes.inputValue.trim();
      contentRef.current?.setDebouncing(true);

      debounceInputValue.current?.(changes);
    };

    const {
      isOpen,
      inputValue,
      highlightedIndex,
      reset,
      openMenu,
      getComboboxProps,
      getInputProps,
      getMenuProps,
      getItemProps,
    } = useCombobox({
      items,
      itemToString: (item) => (item ? localeField(item, 'name') : ''),
      onInputValueChange: handleInputValueChange,
      onSelectedItemChange: (changes) => {
        if (
          changes.selectedItem &&
          (changes.type === useCombobox.stateChangeTypes.InputKeyDownEnter ||
            changes.type === useCombobox.stateChangeTypes.ItemClick)
        ) {
          reset();
          if (isProduct(changes.selectedItem) && typeof window !== 'undefined') {
            let url = productUrl(changes.selectedItem);
            if (router.locale === 'en') {
              url = `/en${url}`;
            }

            window.location.href = url;
          } else {
            router.push(`${categoryUrl(changes.selectedItem)}?s=${oldInputValue.current}`);
          }
          onInputClose?.();
        }
      },
    });

    const handleSearchFocus = () => {
      if (!isOpen) {
        openMenu();
      }
    };

    const handleSearchKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (e.key === 'Enter' && inputValue) {
        reset();
        router.push(`${localizedLink('shop')}?s=${inputValue}`);
        onInputClose?.();
      }
    };

    const handleFetchMore = () => {
      if (data?.products?.pageInfo.hasNextPage && data?.products?.pageInfo.endCursor) {
        fetchMore({
          variables: {
            after: data.products.pageInfo.endCursor,
          },
        });
      }
    };

    return (
      <Box position="relative" w="full" {...container} {...getComboboxProps()}>
        <InputGroup {...inputGroup}>
          <InputLeftElement zIndex={7} h="full">
            <Magnifier />
          </InputLeftElement>
          <Input
            placeholder={t('search')}
            bg="bodyBg"
            zIndex={6}
            _focus={{ boxShadow: 'none' }}
            {...input}
            {...getInputProps({ ref, onFocus: handleSearchFocus, onKeyDown: handleSearchKeyDown })}
          />
          {!hideCloseButton && (
            <InputRightElement h="full" zIndex={12}>
              <IconButton
                aria-label={t('close')}
                variant="unstyled"
                display="flex"
                icon={<Cross />}
                onClick={onInputClose}
              />
            </InputRightElement>
          )}
        </InputGroup>
        <SearchAutocompleteContent
          ref={contentRef}
          items={items}
          isOpen={forceOpen || isOpen}
          loading={loading || categoriesLoading}
          inputValue={inputValue}
          highlightedIndex={highlightedIndex}
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          onFetchMore={handleFetchMore}
          hasMore={data?.products?.pageInfo.hasNextPage}
          menu={menu}
        />
      </Box>
    );
  },
);

SearchAutocomplete.displayName = 'SearchAutocomplete';

export default SearchAutocomplete;
