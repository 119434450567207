import { useContext } from 'react';

import { SaleorContext } from 'context/saleor';

function useCart() {
  const rootState = useContext(SaleorContext);

  return rootState.cart;
}

export { useCart };
