import { Box, Collapse, Flex } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useWindowSize } from 'hooks/useWindowSize';
import { MenuItem } from 'types';
import MenuTab from './drawer-tabs/menu';

interface MobileMenuProps {
  items?: MenuItem[];
  isOpen: boolean;
  onClose(): void;
}

const MobileMenu: React.FC<MobileMenuProps> = observer(({ isOpen, items, onClose }) => {
  const { height } = useWindowSize({ height: 'calc(100vh - 55px)' }, { height: -55 });

  return (
    <Collapse in={isOpen}>
      <Flex bg="bodyBg" style={{ height }}>
        <Box p={0} h="full" position="relative" w="full" maxW="full" overflow="hidden">
          <MenuTab items={items} onClick={onClose} isOpen={isOpen} />
        </Box>
      </Flex>
    </Collapse>
  );
});

export default MobileMenu;
