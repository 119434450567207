import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { VStack } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Select } from '@chakra-ui/select';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import React, { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import urljoin from 'url-join';

import { Size } from 'context/shop';
import { useShop } from 'hooks';
import { CURRENCY_SYMBOLS } from 'lib/consts';
import { getLocalizedCurrentUrl } from 'lib/utils/url';

export interface SettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface SettingsForm {
  language: string;
  currency: string;
  size: string;
}

export const SettingsModal: FC<SettingsModalProps> = ({ isOpen, onClose }) => {
  const router = useRouter();
  const {
    localePaths,
    availableCurrencies,
    currency,
    changeCurrency,
    availableSizes,
    size,
    changeSize,
  } = useShop();
  const { t } = useTranslation('common');
  const { register, handleSubmit, reset } = useForm<SettingsForm>({
    defaultValues: {
      language: router.locale,
      currency,
    },
  });

  useEffect(() => {
    reset({
      language: router.locale,
      currency,
      size,
    });
  }, [router.locale, reset, currency, size]);

  const onSubmit: SubmitHandler<SettingsForm> = (data) => {
    if (data.currency && availableCurrencies.includes(data.currency)) {
      changeCurrency(data.currency);
    }

    if (data.size && availableSizes.includes(data.size) && data.size !== size) {
      changeSize(data.size as Size);
    }

    if (
      data.language !== router.locale &&
      router.locales.includes(data.language) &&
      typeof window !== 'undefined'
    ) {
      const destination = getLocalizedCurrentUrl(router, data.language, localePaths);
      document.cookie = `NEXT_LOCALE=${data.language}`;
      window.location.href = urljoin(
        data.language !== router.defaultLocale ? `/${data.language}` : '/',
        destination,
      );
      // router.push(destination, null, { locale: data.language });
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('settings.title')}</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <VStack spacing={4}>
              <FormControl id="language">
                <FormLabel>{t('languages.title')}</FormLabel>
                <Select {...register('language')}>
                  {router.locales.map((locale) => (
                    <option key={locale} value={locale}>
                      {t(`languages.${locale}`)}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl id="size">
                <FormLabel>{t('sizes.title')}</FormLabel>
                <Select {...register('size')}>
                  {availableSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl id="currency">
                <FormLabel>{t('currency.title')}</FormLabel>
                <Select {...register('currency')}>
                  {availableCurrencies.map((availableCurrency) => (
                    <option key={availableCurrency} value={availableCurrency}>
                      {CURRENCY_SYMBOLS[availableCurrency]} {availableCurrency}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button type="submit" variant="reversed">
              {t('save')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
