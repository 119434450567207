import { Alert, AlertProps } from '@chakra-ui/react';
import { GraphQLError as ApolloGraphQLError } from 'graphql';
import useTranslation from 'next-translate/useTranslation';

import { GraphQLError } from 'types';

interface GqlErrorsAlertProps extends AlertProps {
  errors?: readonly ApolloGraphQLError[] | GraphQLError[] | Error;
}

function GqlErrorsAlert({ errors, ...rest }: GqlErrorsAlertProps) {
  const { t } = useTranslation('common');

  if (!errors || (Array.isArray(errors) && errors.length === 0)) return null;

  function tf(key: string, fallback: string): string {
    const trans = t(key, null, { fallback: 'fallback' });

    return trans === 'common:fallback' ? fallback : trans;
  }

  return (
    <>
      {errors instanceof Error ? (
        <Alert status="error" {...rest}>
          {errors.message}
        </Alert>
      ) : errors[0] instanceof ApolloGraphQLError ? (
        (errors as readonly ApolloGraphQLError[]).map((error, i) => (
          <Alert key={i} status="error" {...rest}>
            {tf(`errors.graphql.${error.name}`, error.message)}
          </Alert>
        ))
      ) : (
        (errors as GraphQLError[]).map((error, i) => (
          <Alert key={i} status="error" {...rest}>
            {tf(
              `errors.${error.__typename}.${error.field ? `${error.field}.` : ''}${error.code}`,
              error.message,
            )}
          </Alert>
        ))
      )}
    </>
  );
}

export default GqlErrorsAlert;
