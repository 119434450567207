import { Box, Flex } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import useTranslation from 'next-translate/useTranslation';
import React, { MouseEventHandler, useEffect, useState } from 'react';

import ForgotPassword from 'components/forms/forgot-password';
import LoginForm from 'components/forms/login';
import RegisterForm from 'components/forms/register';
import MenuButton from 'components/user/menu-button';
import MenuLink from 'components/user/menu-link';
import { useAuth } from 'hooks/auth';

export interface AccountTabProps {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

function AccountTab({ onClick }: AccountTabProps) {
  const { isAuthenticated, signOut } = useAuth();
  const { t } = useTranslation('common');
  const [route, setRoute] = useState('login');

  useEffect(() => {
    if (!isAuthenticated && route === 'authenticated') {
      setRoute('login');
    } else if (isAuthenticated && route !== 'authenticated') {
      setRoute('authenticated');
    }
  }, [isAuthenticated, route]);

  if (!isAuthenticated) {
    let visibleForm: React.ReactNode;

    switch (route) {
      case 'register':
        visibleForm = <RegisterForm onRouteChange={setRoute} loginLink />;
        break;
      case 'reset-password':
        visibleForm = <ForgotPassword onRouteChange={setRoute} title />;
        break;
      case 'login':
      default:
        visibleForm = <LoginForm onRouteChange={setRoute} registerLink />;
    }

    return <Box p={4}>{visibleForm}</Box>;
  }

  return (
    <Flex direction="column" as="nav">
      <MenuLink href="/account" onClick={onClick} textStyle="mobileMenuItem">
        {t('account.account')}
      </MenuLink>
      <MenuLink href="/account/addresses" onClick={onClick} textStyle="mobileMenuItem">
        {t('account.addresses')}
      </MenuLink>
      <MenuLink href="/account/orders" onClick={onClick} textStyle="mobileMenuItem">
        {t('account.orders')}
      </MenuLink>
      <MenuButton onClick={signOut} textStyle="mobileMenuItem">
        {t('account.logout')}
      </MenuButton>
    </Flex>
  );
}

export default observer(AccountTab);
