import { Alert, Button, FormControl, FormLabel, Heading, Input, VStack } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import GqlErrorsAlert from 'components/errors/gql-errors-alert';
import { useAuth } from 'hooks';
import { useMutationFunction } from 'hooks/useMutationFunction';
import { RequestPasswordResetVariables } from 'lib/graphql/types/RequestPasswordReset';

interface ForgotPasswordFormProps {
  onRouteChange(route: string): void;
  title?: boolean;
  fullWidthButton?: boolean;
}

function ForgotPasswordForm({
  onRouteChange,
  title = false,
  fullWidthButton = true,
}: ForgotPasswordFormProps) {
  const { register, handleSubmit } = useForm<RequestPasswordResetVariables>();
  const auth = useAuth();
  const { mutate: resetPasswordRequest, loading, error, success } = useMutationFunction(
    auth.resetPasswordRequest,
  );
  const { t } = useTranslation('common');

  const onSubmit: SubmitHandler<RequestPasswordResetVariables> = async (data) => {
    await resetPasswordRequest(data.email);
  };

  const handleLogin = useCallback(() => {
    onRouteChange('login');
  }, [onRouteChange]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4}>
        {title && (
          <Heading as="h2" size="sm" mb={8} textTransform="uppercase">
            {t('forgotPassword.title')}
          </Heading>
        )}

        <GqlErrorsAlert errors={error} />

        {success && <Alert status="success">{t('forgotPassword.success')}</Alert>}

        <FormControl id="forgot-password-email" isRequired>
          <FormLabel fontSize="sm">{t('email')}</FormLabel>
          <Input type="email" {...register('email')} required />
        </FormControl>

        <Button
          type="submit"
          variant="reversed"
          w={fullWidthButton ? 'full' : 'auto'}
          isLoading={loading}
        >
          {t('send')}
        </Button>

        <Button variant="link" onClick={handleLogin}>
          {t('backToLogin')}
        </Button>
      </VStack>
    </form>
  );
}

export default ForgotPasswordForm;
