import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Button,
  Container,
  Flex,
  Heading,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import React, { FC, PropsWithChildren, useState } from 'react';
import { useForm } from 'react-hook-form';

import Minus from 'components/i/Minus';
import PaperPlane from 'components/i/PaperPlane';
import Plus from 'components/i/Plus';
import Link from 'components/link';
import { useErrorToast, useSuccessToast } from 'hooks/toast';
import { useLocalizedLink } from 'hooks/useLocalizedLink';
import { pageLinks } from 'lib/links';
import Gmaps from './gmaps';
import SettingsWidget from './settings-widget';

interface FooterColProps {
  title: string;
}

interface FooterAccordionItemProps {
  title: string;
  isLast?: boolean;
}

interface NavLinkProps {
  title: string;
  href: string;
}

function FooterCol({ title, children }: PropsWithChildren<FooterColProps>) {
  return (
    <Stack direction="column" flex={1}>
      <Heading as="h4" variant="footerNav">
        {title}
      </Heading>
      <Stack direction="column" spacing={2}>
        {children}
      </Stack>
    </Stack>
  );
}

function NavLink({ href, title }: NavLinkProps) {
  return <Link href={href}>{title}</Link>;
}

const FooterAccordionItem: FC<FooterAccordionItemProps> = ({ title, children }) => {
  return (
    <AccordionItem border="none">
      {({ isExpanded }) => (
        <>
          <AccordionButton
            boxShadow="none"
            bg={isExpanded ? 'white' : 'transparent'}
            _active={{ boxShadow: 'none' }}
            _focus={{ boxShadow: 'none' }}
            _hover={{ background: isExpanded ? 'white' : 'transparent' }}
            pb={0}
            pt={6}
          >
            <Flex w="full" borderBottom="1px" borderColor="border" pb={6}>
              <Box flex="1" textAlign="left">
                {title}
              </Box>
              {isExpanded ? <Minus /> : <Plus />}
            </Flex>
          </AccordionButton>
          <AccordionPanel py={6} px={6} bg={isExpanded ? 'white' : 'transparent'}>
            <VStack spacing={5} align="flex-start">
              {children}
            </VStack>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

function Footer(props: BoxProps) {
  const { t } = useTranslation('common');
  const { locale, defaultLocale } = useRouter();
  const { register, handleSubmit } = useForm<{ email: string }>();
  const [loading, setLoading] = useState(false);
  const errorToast = useErrorToast();
  const successToast = useSuccessToast();
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });
  const localizeLink = useLocalizedLink();

  const getLink = (page: keyof typeof pageLinks): string => {
    if (!pageLinks[page]) return '/';

    return (
      (pageLinks[page]?.[locale] as string) ?? (pageLinks[page]?.[defaultLocale] as string) ?? '/'
    );
  };

  const handleAccordionChange = () => {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        window.scroll({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
      }, 200);
    }
  };

  const onNewsletterSubmit = async ({ email }: { email: string }) => {
    if (!email) return;

    setLoading(true);

    try {
      const response = await fetch('/api/newsletter', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      setLoading(false);
      if (response.ok) {
        successToast({
          description: t('footer.newsletterSuccess'),
        });
      } else {
        try {
          const jsonResponse = await response.json();
          if (jsonResponse.code === 'duplicate_parameter') {
            errorToast({
              description: t('footer.newsletterExists'),
            });
          } else {
            errorToast({
              description: t('footer.newsletterError'),
            });
          }
        } catch (_e) {
          errorToast({
            description: t('footer.newsletterError'),
          });
        }
      }
    } catch (_e) {
      setLoading(false);
      errorToast({
        description: t('footer.newsletterError'),
      });
    }
  };

  const customerCare = (
    <>
      <NavLink href={getLink('contact')} title={t('links.support')} />
      <NavLink href={getLink('faq')} title={t('links.faq')} />
      <NavLink href={getLink('ordersInfo')} title={t('links.ordersInfo')} />
      <NavLink href={getLink('returns')} title={t('links.returns')} />
      <NavLink href={getLink('installmentPayments')} title={t('links.installments')} />
    </>
  );

  const termsOfUse = (
    <>
      <NavLink href={getLink('terms')} title={t('links.terms')} />
      <NavLink href={getLink('selling')} title={t('links.sellingConditions')} />
      <NavLink href={getLink('paymentMethods')} title={t('links.paymentMethods')} />
      <NavLink href={getLink('pricePolicy')} title={t('links.pricePolicy')} />
    </>
  );

  const hypeclothinga = (
    <>
      <NavLink href={getLink('aboutUs')} title={t('links.aboutUs')} />
      <NavLink href={getLink('whereWeAre')} title={t('links.whereWeAre')} />
      <NavLink href={getLink('workWithUs')} title={t('links.workWithUs')} />
      <NavLink href={getLink('authenticity')} title={t('links.authenticity')} />
    </>
  );

  const gMaps = (
    <>
      <Gmaps />
    </>
  );

  return (
    <Box as="footer" bg="darkBackground" mt={16} {...props}>
      <Container maxW="container.3xl" py={isMobile ? 0 : 16} px={4}>
        {isMobile ? (
          <>
            <Box p={5}>
              <Heading as="h4" fontSize="xl" mb={2}>
                Newsletter
              </Heading>
              <form onSubmit={handleSubmit(onNewsletterSubmit)}>
                <Flex align="flex-end">
                  <Input
                    type="email"
                    {...register('email')}
                    placeholder={t('footer.email')}
                    bg="white"
                    required
                  />
                  <Button
                    type="submit"
                    variant="reversed"
                    px={5}
                    letterSpacing="normal"
                    isLoading={loading}
                  >
                    <PaperPlane fill="currentColor" width="1.5em" height="1.5em" />
                  </Button>
                </Flex>
              </form>
              <Text fontSize="xs" mt={2}>
                <Trans
                  i18nKey="common:footer.newsletterPrivacy"
                  components={[
                    <Link href={localizeLink('terms')} />,
                    <Link href={localizeLink('privacy')} />,
                  ]}
                />
              </Text>
              <Flex direction="column" h="200px" mb={8} mt={8}>
                <Heading as="h4" fontSize="xl" mb={2}>
                  {t('links.whereToFindUs')}
                </Heading>
                {gMaps}
              </Flex>
              <Flex direction="column" justify="center" mb={8}>
                <Heading as="h4" fontSize="xl" mb={2}>
                  {t('links.settings')}
                </Heading>
                <SettingsWidget />
              </Flex>
            </Box>
            <Accordion allowToggle onChange={handleAccordionChange}>
              <FooterAccordionItem title={t('links.customerCare')}>
                {customerCare}
              </FooterAccordionItem>
              <FooterAccordionItem title={t('links.termsOfUse')}>{termsOfUse}</FooterAccordionItem>
              <FooterAccordionItem title="Hype Clothinga">{hypeclothinga}</FooterAccordionItem>
            </Accordion>
          </>
        ) : (
          <>
            <SimpleGrid columns={{ base: 1, lg: 1 }} mb={12} spacing={8}>
              <Flex direction="column" justify="center" align="center" h="400px">
                <Heading as="h4" _before={{ borderBottom: 0 }} variant="footerNav">
                  {t('links.whereToFindUs')}
                </Heading>
                {gMaps}
              </Flex>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 3, lg: 3 }} mb={12} spacing={8}>
              <Box></Box>
              <Flex direction="column" justify="center" align="center">
                <Heading as="h4" _before={{ borderBottom: 0 }} variant="footerNav">
                  {t('links.settings')}
                </Heading>
                <SettingsWidget />
              </Flex>
              <Box></Box>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 2, lg: 4 }} spacing={8}>
              <FooterCol title={t('links.customerCare')}>{customerCare}</FooterCol>
              <FooterCol title={t('links.termsOfUse')}>{termsOfUse}</FooterCol>
              <FooterCol title="Hype Clothinga">{hypeclothinga}</FooterCol>

              <FooterCol title="Newsletter">
                <form onSubmit={handleSubmit(onNewsletterSubmit)}>
                  <Flex align="flex-end">
                    <Input
                      type="email"
                      {...register('email')}
                      placeholder={t('footer.email')}
                      bg="white"
                      required
                    />
                    <Button
                      type="submit"
                      variant="reversed"
                      px={5}
                      letterSpacing="normal"
                      isLoading={loading}
                    >
                      <PaperPlane fill="currentColor" width="1.5em" height="1.5em" />
                    </Button>
                  </Flex>
                </form>
                <Text fontSize="xs">
                  <Trans
                    i18nKey="common:footer.newsletterPrivacy"
                    components={[
                      <Link href={localizeLink('terms')} />,
                      <Link href={localizeLink('privacy')} />,
                    ]}
                  />
                </Text>
              </FooterCol>
            </SimpleGrid>
          </>
        )}
      </Container>
    </Box>
  );
}

export default Footer;
