import { Box, Button, FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import StrikeText from 'components/strike-text';
import { useAuth } from 'hooks/auth';
import { useSuccessToast } from 'hooks/toast';
import { useMutationFunction } from 'hooks/useMutationFunction';
import { AccountRegisterVariables } from 'lib/graphql/types/AccountRegister';
import GqlErrorsAlert from '../errors/gql-errors-alert';

interface RegisterFormProps {
  onRouteChange?(route: string): void;
  onSubmit?(): void;
  loginLink?: boolean;
  fullWidthButton?: boolean;
  redirectUrl?: string;
}

function RegisterForm({
  onRouteChange,
  onSubmit: onSubmitCallback,
  redirectUrl,
  loginLink = false,
  fullWidthButton = true,
}: RegisterFormProps) {
  const { register, handleSubmit } = useForm<AccountRegisterVariables>();
  const auth = useAuth();
  const router = useRouter();
  const { mutate: registerAccount, loading, error } = useMutationFunction(auth.registerAccount);
  const { t } = useTranslation('common');
  const successToast = useSuccessToast();

  const handleLogin = useCallback(() => {
    onRouteChange?.('login');
  }, [onRouteChange]);

  const onSubmit: SubmitHandler<AccountRegisterVariables> = async (data) => {
    const registerResult = await registerAccount(data.email, data.password, redirectUrl);

    if (registerResult?.signedIn) {
      successToast({
        description: t('register.welcome'),
      });

      if (router.query.redirectTo) {
        router.push(router.query.redirectTo as string);
      }
    }

    onSubmitCallback?.();
  };

  return (
    <Box as="form" h="full" onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4} h="full">
        <GqlErrorsAlert errors={error} />

        <FormControl id="register-email" isRequired>
          <FormLabel fontSize="sm">{t('email')}</FormLabel>
          <Input type="email" {...register('email')} required />
        </FormControl>
        <FormControl id="register-password" isRequired>
          <FormLabel fontSize="sm">{t('password')}</FormLabel>
          <Input type="password" {...register('password')} required />
        </FormControl>

        <Box flex={1} />

        <Button
          type="submit"
          variant="reversed"
          w={fullWidthButton ? 'full' : 'auto'}
          isLoading={loading}
        >
          {t('register.register')}
        </Button>

        {loginLink && (
          <>
            <StrikeText>{t('or')}</StrikeText>

            <Button variant="link" onClick={handleLogin}>
              {t('backToLogin')}
            </Button>
          </>
        )}
      </VStack>
    </Box>
  );
}

export default RegisterForm;
