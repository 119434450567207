/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  chakra,
  DrawerContentProps,
  ModalFocusScope,
  SystemStyleObject,
  useModalContext,
  useStyles,
} from '@chakra-ui/react';
import { cx } from '@chakra-ui/utils';
import React, { forwardRef } from 'react';

/**
 * ModalContent is used to group modal's content. It has all the
 * necessary `aria-*` properties to indicate that it is a modal
 */
export const DrawerContent = forwardRef<'section', DrawerContentProps>((props, ref) => {
  const { className, children, ...rest } = props;

  const { getDialogProps, getDialogContainerProps } = useModalContext();

  const dialogProps = getDialogProps(rest, ref) as any;
  const containerProps = getDialogContainerProps();

  const _className = cx('chakra-modal__content', className);

  const styles = useStyles();

  const dialogStyles: SystemStyleObject = {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    outline: 0,
    ...styles.dialog,
  };

  const dialogContainerStyles: SystemStyleObject = {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    left: 0,
    top: 0,
    ...styles.dialogContainer,
  };

  return (
    <chakra.div
      {...containerProps}
      className="chakra-modal__content-container"
      __css={dialogContainerStyles}
    >
      <ModalFocusScope>
        <chakra.div
          className={_className}
          {...dialogProps}
          __css={{
            ...dialogStyles,
            ...{
              position: 'fixed',
              width: '100%',
              right: 0,
              top: 0,
              bottom: 0,
              transform: 'translateX(0%) translateZ(0px)',
              transition: 'none',
            },
          }}
        >
          {children}
        </chakra.div>
      </ModalFocusScope>
    </chakra.div>
  );
});

export default DrawerContent;
