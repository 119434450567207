import { Translate } from 'next-translate';

import { MenuItem } from 'types';

export function getMenu(t: Translate): Record<string, MenuItem> {
  return {
    clothing: {
      name: t('common:menu.clothing.title'),
      href: '/abbigliamento',
      hrefEn: '/clothing',
      children: [
        {
          name: t('common:menu.clothing.sweatshirts.title'),
          href: '/felpe',
          hrefEn: '/sweatshirts',
          children: [
            {
              name: t('common:menu.clothing.sweatshirts.hoodies'),
              href: '/felpe-con-cappuccio',
              hrefEn: '/hoodies',
            },
            {
              name: t('common:menu.clothing.sweatshirts.crewneck-sweatshirts'),
              href: '/felpe-girocollo',
              hrefEn: '/crewneck-sweatshirts',
            },
            {
              name: t('common:menu.clothing.sweatshirts.fleece-sweatshirts'),
              href: '/felpe-fleece',
              hrefEn: '/fleece-sweatshirts',
            },
            {
              name: t('common:menu.clothing.sweatshirts.zip-up-sweatshirts'),
              href: '/felpe-con-zip',
              hrefEn: '/zip-up-sweatshirts',
            },
            {
              name: t('common:menu.show-all'),
              href: '/felpe',
              hrefEn: '/sweatshirts',
            },
          ],
        },
        {
          name: t('common:menu.clothing.t-shirts'),
          href: '/magliette',
          hrefEn: '/t-shirts',
          children: [
            {
              name: t('common:menu.clothing.short-sleeves'),
              href: '/magliette-maniche-corte',
              hrefEn: '/short-sleeves-t-shirts',
            },
            {
              name: t('common:menu.clothing.long-sleeves'),
              href: '/magliette-maniche-lunghe',
              hrefEn: '/long-sleeves-t-shirts',
            },
            {
              name: t('common:menu.clothing.undershirts'),
              href: '/canottiere',
              hrefEn: '/undershirts',
            },
            {
              name: t('common:menu.show-all'),
              href: '/magliette',
              hrefEn: '/t-shirts',
            },
          ],
        },
        {
          name: t('common:menu.clothing.jackets'),
          href: '/giacche',
          hrefEn: '/jackets',
          children: [
            {
              name: t('common:menu.clothing.down-jackets'),
              href: '/piumini',
              hrefEn: '/down-jackets',
            },
            {
              name: t('common:menu.clothing.windbreakers'),
              href: '/giacche-a-vento',
              hrefEn: '/windbreakers',
            },
            {
              name: t('common:menu.clothing.fleece-jackets'),
              href: '/giacche-fleece',
              hrefEn: '/fleece-jackets',
            },
            {
              name: t('common:menu.clothing.denim-jackets'),
              href: '/giacche-di-jeans',
              hrefEn: '/denim-jackets',
            },
            {
              name: t('common:menu.clothing.outdoor-jackets'),
              href: '/giacche-tecniche',
              hrefEn: '/outdoor-jackets',
            },
            {
              name: t('common:menu.clothing.gilet'),
              href: '/gilet',
              hrefEn: '/gilet',
            },
            {
              name: t('common:menu.clothing.coats'),
              href: '/cappotti',
              hrefEn: '/coats',
            },
            {
              name: t('common:menu.show-all'),
              href: '/giacche',
              hrefEn: '/jackets',
            },
          ],
        },
        {
          name: t('common:menu.clothing.trousers'),
          href: '/pantaloni',
          hrefEn: '/trousers',
          children: [
            {
              name: t('common:menu.clothing.short-pants'),
              href: '/pantaloni-corti',
              hrefEn: '/short-pants',
            },
            {
              name: t('common:menu.clothing.jeans'),
              href: '/jeans',
              hrefEn: '/jeans',
            },
            {
              name: t('common:menu.clothing.long-pants'),
              href: '/pantaloni-lunghi',
              hrefEn: '/long-pants',
            },
            {
              name: t('common:menu.show-all'),
              href: '/pantaloni',
              hrefEn: '/trousers',
            },
          ],
        },
        {
          name: t('common:menu.clothing.shirts'),
          href: '/camicie',
          hrefEn: '/shirts',
          children: [
            {
              name: t('common:menu.clothing.long-sleeves-shirts'),
              href: '/camicie-maniche-lunghe',
              hrefEn: '/long-sleeves-shirts',
            },
            {
              name: t('common:menu.clothing.short-sleeves-shirts'),
              href: '/camicie-maniche-corte',
              hrefEn: '/short-sleeves-shirts',
            },
            {
              name: t('common:menu.show-all'),
              href: '/camicie',
              hrefEn: '/shirts',
            },
          ],
        },
        {
          name: t('common:menu.clothing.knitwear'),
          href: '/maglieria',
          hrefEn: '/knitwear',
          children: [
            {
              name: t('common:menu.clothing.sweaters'),
              href: '/maglieria',
              hrefEn: '/knitwear',
            },
            {
              name: t('common:menu.show-all'),
              href: '/maglieria',
              hrefEn: '/knitwear',
            },
          ],
        },
        {
          name: t('common:menu.clothing.underwear'),
          href: '/intimo',
          hrefEn: '/underwear',
        },
        {
          name: t('common:menu.clothing.skirts'),
          href: '/gonne',
          hrefEn: '/skirts',
        },
        {
          name: t('common:menu.clothing.swimsuits'),
          href: '/costumi',
          hrefEn: '/swimsuits',
        },
        {
          name: t('common:menu.show-all'),
          href: '/abbigliamento',
          hrefEn: '/clothing',
        },
      ],
    },
    accessories: {
      name: t('common:menu.accessories.title'),
      href: '/accessori',
      hrefEn: '/accessories',
      children: [
        {
          name: t('common:menu.accessories.bags'),
          href: '/borse',
          hrefEn: '/bags',
        },
        {
          name: t('common:menu.accessories.water-bottles'),
          href: '/borracce',
          hrefEn: '/water-bottles',
        },
        {
          name: t('common:menu.accessories.headdress'),
          href: '/cappelli',
          hrefEn: '/headdress',
        },
        {
          name: t('common:menu.accessories.slippers'),
          href: '/ciabatte',
          hrefEn: '/slippers',
        },
        {
          name: t('common:menu.accessories.belts'),
          href: '/cinture',
          hrefEn: '/belts',
        },
        {
          name: t('common:menu.accessories.masks'),
          href: '/mascherine',
          hrefEn: '/masks',
        },
        {
          name: t('common:menu.accessories.sunglasses'),
          href: '/occhiali-da-sole',
          hrefEn: '/sun-glasses',
        },
        {
          name: t('common:menu.accessories.umbrellas'),
          href: '/ombrelli',
          hrefEn: '/umbrellas',
        },
        {
          name: t('common:menu.accessories.wrist-watches'),
          href: '/orologi-da-polso',
          hrefEn: '/watches',
        },
        {
          name: t('common:menu.accessories.balaclava'),
          href: '/passamontagna',
          hrefEn: '/balaclava',
        },
        {
          name: t('common:menu.accessories.keychains'),
          href: '/portachiavi',
          hrefEn: '/keychains',
        },
        {
          name: t('common:menu.accessories.wallets'),
          href: '/portafogli',
          hrefEn: '/wallets',
        },
        {
          name: t('common:menu.accessories.scarves'),
          href: '/sciarpe',
          hrefEn: '/scarves',
        },
        {
          name: t('common:menu.accessories.skateboards'),
          href: '/skateboard',
          hrefEn: '/skateboards',
        },
        {
          name: t('common:menu.show-all'),
          href: '/accessori',
          hrefEn: '/accessories',
        },
      ],
    },
    brands: {
      name: 'Brands',
      href: '/brand',
      hrefEn: '/brands',
      noHeader: true,
      children: [
        {
          name: 'A',
          isHeader: true,
        },
        {
          name: 'Adidas',
          href: '/adidas',
          hrefEn: '/adidas',
          children: [
            {
              name: 'Ultra-Boost',
              href: '/adidas-ultra-boost',
              hrefEn: '/adidas-ultra-boost',
            },
            {
              name: 'Tubular',
              href: '/adidas-tubular',
              hrefEn: '/adidas-tubular',
            },
            {
              name: 'NMD',
              href: '/adidas-nmd',
              hrefEn: '/adidas-nmd',
            },
            {
              name: 'Y-3',
              href: '/adidas-y-3',
              hrefEn: '/adidas-y-3',
            },
            {
              name: 'Raf Simons',
              href: '/adidas-raf-simons',
              hrefEn: '/adidas-raf-simons',
            },
            {
              name: 'EQT',
              href: '/adidas-eqt',
              hrefEn: '/adidas-eqt',
            },
            {
              name: 'Superstar',
              href: '/adidas-superstar',
              hrefEn: '/adidas-superstar',
            },
            {
              name: 'Stan Smith',
              href: '/adidas-stan-smith',
              hrefEn: '/adidas-stan-smith',
            },
            {
              name: '4D',
              href: '/adidas-4d',
              hrefEn: '/adidas-4d',
            },
            {
              name: t('common:menu.show-all'),
              href: '/adidas',
              hrefEn: '/adidas',
            },
          ],
        },
        {
          name: 'Alyx',
          href: '/alyx',
          hrefEn: '/alyx',
        },
        {
          name: 'Ambush',
          href: '/ambush',
          hrefEn: '/ambush',
        },
        {
          name: 'Anti Social Social Club',
          href: '/anti-social-social-club',
          hrefEn: '/anti-social-social-club',
        },
        {
          name: 'Asics',
          href: '/asics',
          hrefEn: '/asics',
        },
        {
          name: 'B',
          isHeader: true,
        },
        {
          name: 'Balenciaga',
          href: '/balenciaga',
          hrefEn: '/balenciaga',
        },
        {
          name: 'Bape',
          href: '/bape',
          hrefEn: '/bape',
        },
        {
          name: 'C',
          isHeader: true,
        },
        {
          name: 'Cacti',
          href: '/cacti',
          hrefEn: '/cacti',
        },
        {
          name: 'Champion',
          href: '/champion',
          hrefEn: '/champion',
        },
        {
          name: 'Chanel',
          href: '/chanel',
          hrefEn: '/chanel',
        },
        {
          name: 'Chinatown Market',
          href: '/chinatown-market',
          hrefEn: '/chinatown-market',
        },
        {
          name: 'Chrome Hearts',
          href: '/chrome-hearts',
          hrefEn: '/chrome-hearts',
        },
        {
          name: 'Clot',
          href: '/clot',
          hrefEn: '/clot',
        },
        {
          name: 'Comme Des Garçons',
          href: '/comme-des-garcons',
          hrefEn: '/comme-des-garcons',
        },
        {
          name: 'Converse',
          href: '/converse',
          hrefEn: '/converse',
        },
        {
          name: 'CPFM',
          href: '/cpfm',
          hrefEn: '/cpfm',
        },
        {
          name: 'D',
          isHeader: true,
        },
        {
          name: 'Daniel Arsham',
          href: '/daniel-arsham-designer',
          hrefEn: '/daniel-arsham-designer',
        },
        {
          name: 'Dior',
          href: '/dior',
          hrefEn: '/dior',
        },
        {
          name: 'Drew House',
          href: '/drew-house',
          hrefEn: '/drew-house',
        },
        {
          name: 'E',
          isHeader: true,
        },
        {
          name: 'Evisu',
          href: '/evisu',
          hrefEn: '/evisu',
        },
        {
          name: 'F',
          isHeader: true,
        },
        {
          name: 'Fear of God',
          href: '/fear-of-god',
          hrefEn: '/fear-of-god',
        },
        {
          name: 'Fragment',
          href: '/fragment',
          hrefEn: '/fragment',
        },
        {
          name: 'Fugazi',
          href: '/fugazi',
          hrefEn: '/fugazi',
        },
        {
          name: 'G',
          isHeader: true,
        },
        {
          name: 'Gucci',
          href: '/gucci',
          hrefEn: '/gucci',
        },
        {
          name: 'Goyard',
          href: '/goyard',
          hrefEn: '/goyard',
        },
        {
          name: 'H',
          isHeader: true,
        },
        {
          name: 'Hermès',
          href: '/hermes',
          hrefEn: '/hermes',
        },
        {
          name: 'Heron Preston',
          href: '/heron-preston',
          hrefEn: '/heron-preston',
        },
        {
          name: 'I',
          isHeader: true,
        },
        {
          name: 'Ikea',
          href: '/ikea',
          hrefEn: '/ikea',
        },
        {
          name: 'J',
          isHeader: true,
        },
        {
          name: 'J Balvin',
          href: '/j-balvin',
          hrefEn: '/j-balvin',
        },
        {
          name: 'Jordan',
          href: '/jordan',
          hrefEn: '/jordan',
          children: [
            {
              name: 'Jordan 1',
              href: '/jordan-1',
              hrefEn: '/jordan-1',
              children: [
                {
                  name: 'Jordan 1 Low',
                  href: '/jordan-1-low',
                  hrefEn: '/jordan-1-low',
                },
                {
                  name: 'Jordan 1 High',
                  href: '/jordan-1-high',
                  hrefEn: '/jordan-1-high',
                },
                {
                  name: 'Jordan 1 Mid',
                  href: '/jordan-1-mid',
                  hrefEn: '/jordan-1-mid',
                },
                {
                  name: t('common:menu.show-all'),
                  href: '/jordan-1',
                  hrefEn: '/jordan-1',
                },
              ],
            },
            {
              name: 'Jordan 2',
              href: '/jordan-2',
              hrefEn: '/jordan-2',
            },
            {
              name: 'Jordan 3',
              href: '/jordan-3',
              hrefEn: '/jordan-3',
            },
            {
              name: 'Jordan 4',
              href: '/jordan-4',
              hrefEn: '/jordan-4',
            },
            {
              name: 'Jordan 5',
              href: '/jordan-5',
              hrefEn: '/jordan-5',
            },
            {
              name: 'Jordan 6',
              href: '/jordan-6',
              hrefEn: '/jordan-6',
            },
            {
              name: 'Jordan 7',
              href: '/jordan-7',
              hrefEn: '/jordan-7',
            },
            {
              name: 'Jordan 8',
              href: '/jordan-8',
              hrefEn: '/jordan-8',
            },
            {
              name: 'Jordan 9',
              href: '/jordan-9',
              hrefEn: '/jordan-9',
            },
            {
              name: 'Jordan 10',
              href: '/jordan-10',
              hrefEn: '/jordan-10',
            },
            {
              name: 'Jordan 11',
              href: '/jordan-11',
              hrefEn: '/jordan-11',
            },
            {
              name: 'Jordan 12',
              href: '/jordan-12',
              hrefEn: '/jordan-12',
            },
            {
              name: 'Jordan 13',
              href: '/jordan-13',
              hrefEn: '/jordan-13',
            },
            {
              name: 'Jordan 14',
              href: '/jordan-14',
              hrefEn: '/jordan-14',
            },
            {
              name: 'Jordan 15',
              href: '/jordan-15',
              hrefEn: '/jordan-15',
            },
            {
              name: 'Jordan Packs',
              href: '/jordan-packs',
              hrefEn: '/jordan-packs',
            },
            {
              name: 'Jordan 25',
              href: '/jordan-25',
              hrefEn: '/jordan-25',
            },
            {
              name: t('common:menu.show-all'),
              href: '/jordan',
              hrefEn: '/jordan',
            },
          ],
        },
        {
          name: 'K',
          isHeader: true,
        },
        {
          name: 'Kaws',
          href: '/kaws-designer',
          hrefEn: '/kaws-designer',
        },
        {
          name: 'Kith',
          href: '/kith',
          hrefEn: '/kith',
        },
        {
          name: 'L',
          isHeader: true,
        },
        {
          name: "Levi's",
          href: '/levis',
          hrefEn: '/levis',
        },
        {
          name: 'Longchamp',
          href: '/longchamp',
          hrefEn: '/longchamp',
        },
        {
          name: 'Louis Vuitton',
          href: '/louis-vuitton',
          hrefEn: '/louis-vuitton',
        },
        {
          name: 'M',
          isHeader: true,
        },
        {
          name: 'Maison Margiela',
          href: '/maison-margiela',
          hrefEn: '/maison-margiela',
        },
        {
          name: 'Marcelo Burlon',
          href: '/marcelo-burlon',
          hrefEn: '/marcelo-burlon',
        },
        {
          name: 'MCM',
          href: '/mcm',
          hrefEn: '/mcm',
        },
        {
          name: 'Medicom Toy',
          href: '/medicom-toy',
          hrefEn: '/medicom-toy',
        },
        {
          name: 'N',
          isHeader: true,
        },
        {
          name: 'New Balance',
          href: '/new-balance',
          hrefEn: '/new-balance',
        },
        {
          name: 'Nike',
          href: '/nike',
          hrefEn: '/nike',
          children: [
            {
              name: 'Nike Basketball',
              href: '/nike-basketball',
              hrefEn: '/nike-basketball',
            },
            {
              name: 'Nike Air Max',
              href: '/nike-air-max',
              hrefEn: '/nike-air-max',
              children: [
                {
                  name: 'Nike Air Max 1',
                  href: '/nike-air-max-1',
                  hrefEn: '/nike-air-max-1',
                },
                {
                  name: 'Nike Air Max 90',
                  href: '/nike-air-max-90',
                  hrefEn: '/nike-air-max-90',
                },
                {
                  name: 'Nike Air Max 95',
                  href: '/nike-air-max-95',
                  hrefEn: '/nike-air-max-95',
                },
                {
                  name: 'Nike Air Max 97',
                  href: '/nike-air-max-97',
                  hrefEn: '/nike-air-max-97',
                },
                {
                  name: 'Nike Air Max 98',
                  href: '/nike-air-max-98',
                  hrefEn: '/nike-air-max-98',
                },
                {
                  name: 'Nike Air Max 270',
                  href: '/nike-air-max-270',
                  hrefEn: '/nike-air-max-270',
                },
                {
                  name: 'Nike Air Max 720',
                  href: '/nike-air-max-720',
                  hrefEn: '/nike-air-max-720',
                },
                {
                  name: 'Nike Air Max 2090',
                  href: '/nike-air-max-2090',
                  hrefEn: '/nike-air-max-2090',
                },
                {
                  name: 'Nike Vapormax',
                  href: '/nike-vapormax',
                  hrefEn: '/nike-vapormax',
                },
                {
                  name: 'Nike Air Max Plus',
                  href: '/nike-air-max-plus',
                  hrefEn: '/nike-air-max-plus',
                },
                {
                  name: t('common:menu.show-all'),
                  href: '/nike-air-max',
                  hrefEn: '/nike-air-max',
                },
              ],
            },
            {
              name: 'Nike Lifestyle',
              href: '/nike-lifestyle',
              hrefEn: '/nike-lifestyle',
              children: [
                {
                  name: 'Nike Blazer',
                  href: '/nike-blazer',
                  hrefEn: '/nike-blazer',
                  children: [
                    {
                      name: 'Nike Blazer Mid',
                      href: '/nike-blazer-mid',
                      hrefEn: '/nike-blazer-mid',
                    },
                    {
                      name: 'Nike Blazer Low',
                      href: '/nike-blazer-low',
                      hrefEn: '/nike-blazer-low',
                    },
                    {
                      name: t('common:menu.show-all'),
                      href: '/nike-blazer',
                      hrefEn: '/nike-blazer',
                    },
                  ],
                },
                {
                  name: 'Nike Huarache',
                  href: '/nike-huarache',
                  hrefEn: '/nike-huarache',
                },
                {
                  name: 'Nike Presto',
                  href: '/nike-presto',
                  hrefEn: '/nike-presto',
                },
                {
                  name: 'Nike React',
                  href: '/nike-react',
                  hrefEn: '/nike-react',
                },
                {
                  name: t('common:menu.show-all'),
                  href: '/nike-lifestyle',
                  hrefEn: '/nike-lifestyle',
                },
              ],
            },
            {
              name: 'Nike Dunk',
              href: '/nike-dunk',
              hrefEn: '/nike-dunk',
              children: [
                {
                  name: 'Nike Dunk Low',
                  href: '/nike-dunk-low',
                  hrefEn: '/nike-dunk-low',
                },
                {
                  name: 'Nike Dunk High',
                  href: '/nike-dunk-high',
                  hrefEn: '/nike-dunk-high',
                },
                {
                  name: 'Nike Dunk SB Low',
                  href: '/nike-dunk-sb-low',
                  hrefEn: '/nike-dunk-sb-low',
                },
                {
                  name: 'Nike Dunk SB High',
                  href: '/nike-dunk-sb-high',
                  hrefEn: '/nike-dunk-sb-high',
                },
                {
                  name: t('common:menu.show-all'),
                  href: '/nike-dunk',
                  hrefEn: '/nike-dunk',
                },
              ],
            },
            {
              name: 'Nike Air Force 1',
              href: '/nike-air-force-1',
              hrefEn: '/nike-air-force-1',
              children: [
                {
                  name: 'Air Force 1 Mid',
                  href: '/nike-air-force-1-mid',
                  hrefEn: '/nike-air-force-1-mid',
                },
                {
                  name: 'Air Force 1 High',
                  href: '/nike-air-force-1-high',
                  hrefEn: '/nike-air-force-1-high',
                },
                {
                  name: 'Air Force 1 Low',
                  href: '/nike-air-force-1-low',
                  hrefEn: '/nike-air-force-1-low',
                },
                {
                  name: t('common:menu.show-all'),
                  href: '/nike-air-force-1',
                  hrefEn: '/nike-air-force-1',
                },
              ],
            },
            {
              name: t('common:menu.show-all'),
              href: '/nike',
              hrefEn: '/nike',
            },
          ],
        },
        {
          name: 'Nocta',
          href: '/nocta',
          hrefEn: '/nocta',
        },
        {
          name: 'O',
          isHeader: true,
        },
        {
          name: 'Off-White',
          href: '/off-white',
          hrefEn: '/off-white',
        },
        {
          name: 'Ovo',
          href: '/ovo',
          hrefEn: '/ovo',
        },
        {
          name: 'P',
          isHeader: true,
        },
        {
          name: 'Palace',
          href: '/palace',
          hrefEn: '/palace',
        },
        {
          name: 'Palm Angels',
          href: '/palm-angels',
          hrefEn: '/palm-angels',
        },
        {
          name: 'Parra',
          href: '/parra',
          hrefEn: '/parra',
        },
        {
          name: 'Patta',
          href: '/patta',
          hrefEn: '/patta',
        },
        {
          name: 'Pharrel Williams',
          href: '/pharrel-williams',
          hrefEn: '/pharrel-williams',
        },
        {
          name: 'Prada',
          href: '/prada',
          hrefEn: '/prada',
        },
        {
          name: 'Puma',
          href: '/puma',
          hrefEn: '/puma',
        },
        {
          name: 'R',
          isHeader: true,
        },
        {
          name: 'Raf Simons',
          href: '/raf-simons',
          hrefEn: '/raf-simons',
        },
        {
          name: 'Readymade',
          href: '/readymade',
          hrefEn: '/readymade',
        },
        {
          name: 'Reebok',
          href: '/reebok',
          hrefEn: '/reebok',
        },
        {
          name: 'Riccardo Tisci',
          href: '/riccardo-tisci',
          hrefEn: '/riccardo-tisci',
        },
        {
          name: 'Rimowa',
          href: '/rimowa',
          hrefEn: '/rimowa',
        },
        {
          name: 'S',
          isHeader: true,
        },
        {
          name: 'Sacai',
          href: '/sacai',
          hrefEn: '/sacai',
        },
        {
          name: 'Sean Wotherspoon',
          href: '/sean-wotherspoon',
          hrefEn: '/sean-wotherspoon',
        },
        {
          name: 'Sorayama',
          href: '/sorayama',
          hrefEn: '/sorayama',
        },
        {
          name: 'Stone Island',
          href: '/stone-island',
          hrefEn: '/stone-island',
        },
        {
          name: 'Stüssy',
          href: '/stussy',
          hrefEn: '/stussy',
        },
        {
          name: 'Supreme',
          href: '/supreme',
          hrefEn: '/supreme',
        },
        {
          name: 'Swarovski',
          href: '/swarovski',
          hrefEn: '/swarovski',
        },
        {
          name: 'T',
          isHeader: true,
        },
        {
          name: 'Takashi Murakami',
          href: '/takashi-murakami',
          hrefEn: '/takashi-murakami',
        },
        {
          name: 'The North Face',
          href: '/the-north-face',
          hrefEn: '/the-north-face',
        },
        {
          name: 'Timberland',
          href: '/timberland',
          hrefEn: '/timberland',
        },
        {
          name: 'Tom Sachs',
          href: '/tom-sachs',
          hrefEn: '/tom-sachs',
        },
        {
          name: 'Travis Scott',
          href: '/travis-scott',
          hrefEn: '/travis-scott',
        },
        {
          name: 'U',
          isHeader: true,
        },
        {
          name: 'Undefeated',
          href: '/undefeated',
          hrefEn: '/undefeated',
        },
        {
          name: 'V',
          isHeader: true,
        },
        {
          name: 'Virgil Abloh',
          href: '/virgil-abloh',
          hrefEn: '/virgil-abloh',
        },
        {
          name: 'Vlone',
          href: '/vlone',
          hrefEn: '/vlone',
        },
        {
          name: 'Y',
          isHeader: true,
        },
        {
          name: 'Yeezy',
          href: '/yeezy',
          hrefEn: '/yeezy',
          children: [
            {
              name: 'Yeezy 350',
              href: '/yeezy-350',
              hrefEn: '/yeezy-350',
            },
            {
              name: 'Yeezy 350 V2',
              href: '/yeezy-350-v2',
              hrefEn: '/yeezy-350-v2',
            },
            {
              name: 'Yeezy 380',
              href: '/yeezy-380',
              hrefEn: '/yeezy-380',
            },
            {
              name: 'Yeezy 450',
              href: '/yeezy-450',
              hrefEn: '/yeezy-450',
            },
            {
              name: 'Yeezy 500',
              href: '/yeezy-500',
              hrefEn: '/yeezy-500',
            },
            {
              name: 'Yeezy 700',
              href: '/yeezy-700',
              hrefEn: '/yeezy-700',
            },
            {
              name: 'Yeezy 700 V2',
              href: '/yeezy-700-v2',
              hrefEn: '/yeezy-700-v2',
            },
            {
              name: 'Yeezy 700 V3',
              href: '/yeezy-700-v3',
              hrefEn: '/yeezy-700-v3',
            },
            {
              name: 'Yeezy 750',
              href: '/yeezy-750',
              hrefEn: '/yeezy-750',
            },
            {
              name: 'Yeezy 950',
              href: '/yeezy-950',
              hrefEn: '/yeezy-950',
            },
            {
              name: 'Yeezy Slide',
              href: '/yeezy-slide',
              hrefEn: '/yeezy-slide',
            },
            {
              name: 'Yeezy Powerphase',
              href: '/yeezy-powerphase',
              hrefEn: '/yeezy-powerphase',
            },
            {
              name: 'Yeezy Cleat',
              href: '/yeezy-cleat',
              hrefEn: '/yeezy-cleat',
            },
            {
              name: 'Nike Yeezy',
              href: '/nike-yeezy',
              hrefEn: '/nike-yeezy',
            },
            {
              name: 'Yeezy QNTM',
              href: '/yeezy-qntm',
              hrefEn: '/yeezy-qntm',
            },
            {
              name: t('common:menu.show-all'),
              href: '/yeezy',
              hrefEn: '/yeezy',
            },
          ],
        },
        {
          name: t('common:menu.other'),
          isHeader: true,
        },
        {
          name: t('common:menu.show-all'),
          href: '/brand',
          hrefEn: '/brands',
        },
      ],
    },
  };
}

export function getMobileMenu(t: Translate): MenuItem[] {
  const menu = getMenu(t);

  return [
    {
      name: 'Sneakers',
      href: '/scarpe',
      hrefEn: '/sneakers',
      children: [
        {
          name: t('common:menu.sneakers.men'),
          href: '/sneakers-uomo',
          hrefEn: '/sneakers-men',
        },
        {
          name: t('common:menu.sneakers.women'),
          href: '/sneakers-donna',
          hrefEn: '/sneakers-women',
        },
        {
          name: t('common:menu.sneakers.kids'),
          href: '/sneakers-bambino',
          hrefEn: '/kids-sneakers',
        },
        {
          name: t('common:menu.show-all'),
          href: '/scarpe',
          hrefEn: '/sneakers',
        },
      ],
    },
    menu.clothing,
    {
      name: t('common:menu.accessories.title'),
      href: '/accessori',
      hrefEn: '/accessories',
      children: [
        {
          name: t('common:menu.accessories.bags'),
          href: '/borse',
          hrefEn: '/bags',
          children: [
            {
              name: t('common:menu.accessories.bags-women'),
              href: '/borse-donna',
              hrefEn: '/bags-women',
            },
            {
              name: t('common:menu.accessories.bags-men'),
              href: '/borse-uomo',
              hrefEn: '/bags-men',
            },
            {
              name: t('common:menu.accessories.belt-bags'),
              href: '/marsupi',
              hrefEn: '/belt-bags',
            },
            {
              name: t('common:menu.accessories.shoulder-bags'),
              href: '/tracolle',
              hrefEn: '/shoulder-bags',
            },
            {
              name: t('common:menu.accessories.backpacks'),
              href: '/zaini',
              hrefEn: '/backpacks',
            },
            {
              name: t('common:menu.accessories.trolleys'),
              href: '/valigie',
              hrefEn: '/trolleys',
            },
            {
              name: t('common:menu.show-all'),
              href: '/borse',
              hrefEn: '/bags',
            },
          ],
        },
        {
          name: t('common:menu.accessories.water-bottles'),
          href: '/borracce',
          hrefEn: '/water-bottles',
        },
        {
          name: t('common:menu.accessories.headdress'),
          href: '/cappelli',
          hrefEn: '/headdress',
          children: [
            {
              name: t('common:menu.accessories.beanies'),
              href: '/berretti',
              hrefEn: '/beanies',
            },
            {
              name: t('common:menu.accessories.hats'),
              href: '/cappelli-con-visiera',
              hrefEn: '/hats',
            },
            {
              name: t('common:menu.show-all'),
              href: '/cappelli',
              hrefEn: '/headdress',
            },
          ],
        },
        {
          name: t('common:menu.accessories.slippers'),
          href: '/ciabatte',
          hrefEn: '/slippers',
        },
        {
          name: t('common:menu.accessories.belts'),
          href: '/cinture',
          hrefEn: '/belts',
        },
        {
          name: t('common:menu.accessories.masks'),
          href: '/mascherine',
          hrefEn: '/masks',
        },
        {
          name: t('common:menu.accessories.sunglasses'),
          href: '/occhiali-da-sole',
          hrefEn: '/sun-glasses',
        },
        {
          name: t('common:menu.accessories.umbrellas'),
          href: '/ombrelli',
          hrefEn: '/umbrellas',
        },
        {
          name: t('common:menu.accessories.wrist-watches'),
          href: '/orologi-da-polso',
          hrefEn: '/watches',
        },
        {
          name: t('common:menu.accessories.balaclava'),
          href: '/passamontagna',
          hrefEn: '/balaclava',
        },
        {
          name: t('common:menu.accessories.keychains'),
          href: '/portachiavi',
          hrefEn: '/keychains',
        },
        {
          name: t('common:menu.accessories.wallets'),
          href: '/portafogli',
          hrefEn: '/wallets',
        },
        {
          name: t('common:menu.accessories.scarves'),
          href: '/sciarpe',
          hrefEn: '/scarves',
        },
        {
          name: t('common:menu.accessories.skateboards'),
          href: '/skateboard',
          hrefEn: '/skateboards',
          children: [
            {
              name: t('common:menu.accessories.skateboard-decks'),
              href: '/skateboard-deck',
              hrefEn: '/skateboard-decks',
            },
            {
              name: t('common:menu.accessories.skateboard-accessories'),
              href: '/accessori-skateboard',
              hrefEn: '/skateboard-accessories',
            },
            {
              name: t('common:menu.show-all'),
              href: '/skateboard',
              hrefEn: '/skateboards',
            },
          ],
        },
        {
          name: t('common:menu.show-all'),
          href: '/accessori',
          hrefEn: '/accessories',
        },
      ],
    },
    {
      name: t('common:menu.design.title'),
      href: '/design',
      hrefEn: '/design',
      children: [
        {
          name: t('common:menu.design.interior'),
          href: '/arredamento',
          hrefEn: '/interior-design',
          children: [
            {
              name: t('common:menu.design.wall-clocks'),
              href: '/orologi-da-parete',
              hrefEn: '/wall-clocks',
            },
            {
              name: t('common:menu.design.rugs'),
              href: '/tappeti',
              hrefEn: '/rugs',
            },
            {
              name: t('common:menu.design.skateboard-decks'),
              href: '/arredamento-skateboard-deck',
              hrefEn: '/interior-design-skateboard-decks',
            },
            {
              name: t('common:menu.show-all'),
              href: '/arredamento',
              hrefEn: '/interior-design',
            },
          ],
        },
        {
          name: t('common:menu.design.collectibles'),
          href: '/collezionabili',
          hrefEn: '/collectibles',
          children: [
            {
              name: 'Be@rbrick',
              href: '/bearbrick',
              hrefEn: '/bearbrick',
              children: [
                {
                  name: 'Be@rbrick 100%',
                  href: '/bearbrick-100',
                  hrefEn: '/bearbrick-100',
                },
                {
                  name: 'Be@rbrick 200%',
                  href: '/bearbrick-200',
                  hrefEn: '/bearbrick-200',
                },
                {
                  name: 'Be@rbrick 400%',
                  href: '/bearbrick-400',
                  hrefEn: '/bearbrick-400',
                },
                {
                  name: 'Be@rbrick 400% + 100%',
                  href: '/bearbrick-400-100',
                  hrefEn: '/bearbrick-400-100',
                },
                {
                  name: 'Be@rbrick 1000%',
                  href: '/bearbrick-1000',
                  hrefEn: '/bearbrick-1000',
                },
                {
                  name: t('common:menu.show-all'),
                  href: '/bearbrick',
                  hrefEn: '/bearbrick',
                },
              ],
            },
            {
              name: 'Kaws',
              href: '/kaws',
              hrefEn: '/kaws',
            },
            {
              name: 'Daniel Arsham',
              href: '/daniel-arsham',
              hrefEn: '/daniel-arsham',
            },
            {
              name: t('common:menu.show-all'),
              href: '/collezionabili',
              hrefEn: '/collectibles',
            },
          ],
        },
        {
          name: t('common:menu.show-all'),
          href: '/design',
          hrefEn: '/design',
        },
      ],
    },
    menu.brands,
  ];
}
